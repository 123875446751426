import React from "react";
import { withRouter } from "react-router-dom";
import BasicForm, { required } from "../lib/BasicForm";
import { Form, Input, Row, Col } from "antd";
import "../css/validateStateColor.css";
import Search from "../lib/widgets/Search";
import { emailValidator } from "./Login";

class Companies extends BasicForm {
  tableLabel = `Uzņēmumu saraksts`;
  newLabel = `Reģistrēt uzņēmumu`;
  editLabel = `Labot uzņēmumu`;

  location = `/companies`;
  source = `companies`;

  trRef = React.createRef();
  recRef = React.createRef();

  formatData = (data) => {
    if (data) {
      for (const i in data) {
        if (data[i] === null) {
          data[i] = undefined;
        }
      }
    }

    return { ...data };
  };

  LvRegNrValidator = ({ getFieldValue }) => ({
    validator(_, value) {
      return new Promise((r, f) => {
        let pvn = getFieldValue(`pvnNr`)?.toString() ?? ``;
        if (pvn.startsWith(`LV`)) {
          let shortPvn = pvn.substring(2);
          if (value === shortPvn) {
            r();
          } else {
            f(`Latvijā reģistrētajam uzņēmumam nav atbilstošs PVN vai reģ. numurs`);
          }
        }
        r();
      });
    },
  });

  PVNnrValidator = () => ({
    validator: (_, value) => {
      return new Promise((r) => {
        const isLV = value?.startsWith(`LV`) ?? false;
        this.setState({ isLVRegNr: isLV });
        r();
      });
    },
  });

  resetFields = (value) => {
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        transpLic: undefined,
        receiverLic: undefined,
      });
      this.trRef?.current?.load(undefined, [{ key: `organizationId`, value }]);
      this.recRef?.current?.load(undefined, [{ key: `organizationId`, value }]);
    }
  };

  form() {
    const { state } = this;
	const {isMobile} = this.props;

    const isEmptyPvnNr =
      this.formRef?.current?.getFieldValue(`pvnNr`)?.length <= 0 ?? true;

    return (
      <>
        <Row>
			<Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
            <Form.Item name="organizationId" label="Uzņēmuma APUS nosaukums">
              <Search
                source="apusOrganizations"
                placeholder="Norādiet uzņēmumu no APUS klasifikatoriem"
                onChange={this.resetFields}
                options={this.userData?.__options}
              />
            </Form.Item>

            <Form.Item
              name="name"
              label="Uzņēmuma nosaukums"
              rules={[required]}
            >
              <Input
                placeholder="Norādiet uzņēmuma nosaukumu"
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name="shortName"
              label="Uzņēmuma īsais nosaukums"
              rules={[required]}
            >
              <Input
                placeholder="Norādiet uzņēmuma īso nosaukumu"
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item name="email" label="E-pasts" rules={[emailValidator]}>
              <Input placeholder="Norādiet e-pastu" autoComplete={false} />
            </Form.Item>

            <Form.Item name="phone" label="Tel. numurs">
              <Input placeholder="Norādiet tel. numuru" autoComplete={false} />
            </Form.Item>

            <Form.Item name="website" label="Mājaslapa">
              <Input placeholder="Norādiet mājaslapu" autoComplete={false} />
            </Form.Item>
            <Form.Item
              name="pvnNr"
              label="PVN numurs"
              rules={[required, this.PVNnrValidator]}
              validateStatus={
                isEmptyPvnNr
                  ? undefined
                  : state.isLVRegNr ?? true
                    ? undefined
                    : `warning`
              }
              help={
                isEmptyPvnNr
                  ? undefined
                  : state.isLVRegNr ?? true
                    ? undefined
                    : `PVN nr. nav reģistrēts Latvijā`
              }
            >
              <Input
                name="pvnNr"
                placeholder="Norādiet PVN numuru"
                autoComplete={false}
                onChange={() => this.forceUpdate()}
              />
            </Form.Item>
            <Form.Item
              name="regNr"
              label="Reģistrācijas nr."
              rules={[required, this.LvRegNrValidator]}
            >
              <Input
                placeholder="Norādiet reģistrācijas nr."
                autoComplete={false}
              />
            </Form.Item>

            <Form.Item name="transpLic" label="Transportētāja licence">
              <Search
                ref={this.trRef}
                source="apusTranspLicence"
                placeholder="Izvēlaties transportētāja licensi"
                extraParameters={[
                  {
                    key: `organizationId`,
                    value:
                      this.formRef?.current?.getFieldValue(`organizationId`) ??
                      null,
                  },
                ]}
                options={this.userData?.__options1}
              />
            </Form.Item>
            <Form.Item name="receiverLic" label="Saņēmēja licence">
              <Search
                ref={this.recRef}
                source="apusReceiverLicence"
                placeholder="Izvēlaties saņēmēja licensi"
                extraParameters={[
                  {
                    key: `organizationId`,
                    value:
                      this.formRef?.current?.getFieldValue(`organizationId`) ??
                      null,
                  },
                ]}
                options={this.userData?.__options2}
              />
            </Form.Item>

            <Form.Item name="sendMethod" label="Sūtīšanas veids">
              <Search
                allowClear={false}
                source="apusSendMethods"
                placeholder="Izvēlaties sūtīšanas veidu"
              />
            </Form.Item>

            <Form.Item
              name="apusUsername"
              label="APUS lietotājvārds"
            >
              <Input
                placeholder="Ievadiet lietotājvārdu"
                autoComplete={false}
              />
            </Form.Item>

            <Form.Item
              name="apusPassword"
              label="APUS parole"
            >
              <Input.Password
                placeholder="Ievadiet paroli"
                autoComplete={false}
              />
            </Form.Item>

          </Col>
        </Row>
      </>
    );
  }
}
export default withRouter(Companies);
