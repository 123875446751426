import React, { Component } from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import { user } from './User';
import { message, Spin, Form, Button } from 'antd';
import { destroy, post, acquire } from './api';

class CustomForm extends Component {
    constructor(props) {
        super(props);

        this.source = null;
        this.location = `/`;

        this.state = {
            id: null,
            case: null,
            data: [],
            item: null,
            loading: false,
            allowSubmit: true,
            isError: false,
            errorTitle: undefined,
            errorMessage: undefined,
        };
    }

    fetchItemData = async (id) => {
        let item = null;

        let error = { isError: false, errorTitle: undefined, errorMessage: undefined };
        if (id && this.state.loading) {
            const response = await acquire(`${this.source}/${id}`, null, null);
            if (response.ok && response.status === 200 && response.data.data!==0) {
                item = { ...response.data };
            } else if (response.data && response.data.error) {
                error = { isError: true, errorTitle: response.data?.title, errorMessage: response.data.error };
                message.error(response.data.error);
            } else {
                error = { isError: true, errorTitle: `Sistēmas kļūda`, errorMessage: `Kaut kas nogāja greizi, mēģiniet vēlreiz.` };
                message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
            }
        }

        this.setState({ loading: false, item, ...error });
    };

    fetchItems = async () => {
        const { state } = this;
        
        let error = { isError: false, errorTitle: undefined, errorMessage: undefined };
        
        let data = [];
        let pagination = state.pagination ?? { page: 1, size: 10, total: 10 };
        const extra = this.getExtraProperties ? this.getExtraProperties() : undefined;
        // console.log(extra);
        const response = await acquire(this.source, pagination.page, pagination.size, extra);
        if (response.ok && response.status === 200 && response.data.data!==0) {
            data = response.data?.data;
            pagination = { ...pagination, ...response.data?.pagination };
        } else if (response.data && response.data.error) {
            message.error(response.data.error);
            error = { isError: true, errorTitle: response.data?.title, errorMessage: response.data.error };
        } else {
            error = { isError: true, errorTitle: `Sistēmas kļūda`, errorMessage: `Kaut kas nogāja greizi, mēģiniet vēlreiz.` };
            message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
        }

        this.setState({ loading: false, data, pagination, ...error });
    };

    load = async () => {
        const { state } = this;

        switch (state.case) {
            case `new`:
                this.setState({ data: [], item: null, loading: false });
                break;

            case `edit`:
                this.fetchItemData(state.id);
                break;

            default:
            case `table`:
                this.fetchItems();
                break;
        }
    };

    setPage = (id, _case) => {
        switch (_case) {
            case `new`:
                this.props.history.push(`${this.location}/new`);
                break;

            case `edit`:
                this.props.history.push(`${this.location}/${id}`);
                break;

            default:
            case `table`:
                this.props.history.push(`${this.location}`);
                break;
        }

        this.setState({ id, case: _case });
        user.sync();
    };


    static getDerivedStateFromProps(props, state) {
        let newFormMatch = matchPath(props.location.pathname, { path: `${props.match.path}/new`, strict: true, exact: true });
        let editFormMatch = matchPath(props.location.pathname, { path: `${props.match.path}/:id`, strict: true, exact: true });

        if (newFormMatch) {
            return {
                id: null,
                case: `new`,
                isError: false,
                loading: false,
                port: props.port,
                searchBarVal: props.searchBarVal,
                allowSubmit: state.case === `new` ? state.allowSubmit : true,
            };
        } else if (editFormMatch && !isNaN(editFormMatch.params.id)) {
            return {
                id: editFormMatch.params.id,
                case: `edit`,
                isError: false,
                port: props.port,
                searchBarVal: props.searchBarVal,
                loading: state.case !== `edit` || state.id !== editFormMatch.params.id || state.loading,
                allowSubmit: state.case === `edit` ? state.allowSubmit : true
            };
        } else {
            return {
                id: null,
                case: `table`,
                isError: false,
                port: props.port,
                searchBarVal: props.searchBarVal,
                loading: state.case !== `table` || state.loading || state.port !== props.port || state.searchBarVal !== props.searchBarVal, 
                allowSubmit: state.case === `table` ? state.allowSubmit : true,
            };
        }
    }

    onChange = () => {
    };

    delete = async () => {
        const { state } = this;
        const response = await destroy(this.source, state.id);
        if (response.ok && response.status === 200 && response.data.data!==0) {
            message.success(response.data.message);
            this.setPage(null, `table`);
        } else if (response.data && response.data.error) {
            message.error(response.data.error);
            this.setState({ allowSubmit: true });
        } else {
            message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
            this.setState({ allowSubmit: true });
        }
        user.sync();
    };

    submit = async (data) => {
        const { state } = this;
        this.setState({ allowSubmit: false });

        const response = await post(this.source, data, state.id);
        if (response.ok && response.status === 200 && response.data.data!==0) {
            message.success(response.data.message);
            this.setPage(null, `table`);
        } else if (response.data && response.data.error) {
            message.error(response.data.error);
            this.setState({ allowSubmit: true });
        } else {
            message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
            this.setState({ allowSubmit: true });
        }
        user.sync();
    };

    goToDefaultPage = () => {
        this.setPage(null, `table`);
    };

    render() {
        const { state } = this;

        if (state.loading) {
            this.load();
            return <Spin size="large" style={{ position: `absolute`, zIndex: 9999, top: `50%`, left: `50%`, transform: `transalte(-50%)` }} />;
        }

        switch (state.case) {
            case `new`:
                return (
                    <div style={{ padding: 15, width: `100%`, minHeight: 250, paddingBottom: 50, position: `relative` }}>
                        <Redirect to={`${this.location}/new`} />
                        <Form
                            layout="vertical"
                            onFinish={this.submit}
                            onValuesChange={this.onChange}
                        >
                            {this.form()}
                            <div style={{ position: `absolute`, bottom: 0, right: 20 }}>
                                <Button onClick={() => this.setPage(null, `table`)}>Atcelt</Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ marginLeft: 10 }}
                                    disabled={!state.allowSubmit}
                                    loading={!state.allowSubmit}
                                >
                                    Saglabāt
                                </Button>
                            </div>
                        </Form>
                    </div>
                );
            case `edit`:
                return (
                    <div style={{ padding: 15, width: `100%`, minHeight: 250, paddingBottom: 50, position: `relative` }}>
                        <Redirect to={`${this.location}/${state.id}`} />
                        <Form
                            layout="vertical"
                            onFinish={this.submit}
                            initialValues={state.data}
                            onValuesChange={this.onChange}
                        >
                            {this.form()}
                            <div style={{ position: `absolute`, bottom: 0, right: 20 }}>
                                <Button type="primary" danger onClick={this.delete}>Dzēst</Button>
                                <Button style={{ marginLeft: 10 }} onClick={() => this.setPage(null, `table`)}>Atcelt</Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ marginLeft: 10 }}
                                    disabled={!state.allowSubmit}
                                    loading={!state.allowSubmit}
                                >
                                    Saglabāt
                                </Button>
                            </div>
                        </Form>
                    </div>
                );

            case `table`:
                return (
                    <>
                        {
                            this.renderItems(state.data)
                        }
                    </>
                );

            default:
                return <></>;
        }
    }
}

export default CustomForm;
