const tables = {
    users: [
        {
            title: `LIETOTĀJVĀRDS`,
            key: `username`,
            sorter: true,
        },
        {
            title: `VĀRDS`,
            key: `name`,
            sorter: true,
        },
        {
            title: `UZVĀRDS`,
            key: `surname`,
            sorter: true,
        },
    ],
    roles: [
        {
            title: `Nosaukums`,
            key: `name`,
            sorter: true,
        },
    ],
    waste: [
        {
            title: ``,
            key: `name`,
            render: (txt, rec) => <>{rec?.type?.name}</>,
        },
        {
            title: `NODOŠANAI`,
            key: `forDisposal`,
            align: `center`,
            render: (txt) =>
                txt ? (
                    <>
                        {txt} m<sup>3</sup>
                    </>
                ) : (
                    <>-</>
                ),
        },
        {
            title: `MAKS. IETILP.`,
            key: `maxCapacity`,
            align: `center`,
            render: (txt) =>
                txt ? (
                    <>
                        {txt} m<sup>3</sup>
                    </>
                ) : (
                    <>-</>
                ),
        },
        {
            title: `ATLIKUMS`,
            key: `remainder`,
            align: `center`,
            render: (txt) =>
                txt ? (
                    <>
                        {txt} m<sup>3</sup>
                    </>
                ) : (
                    <>-</>
                ),
        },
        {
            title: `PAREDZĒTS RADĪT`,
            key: `expectedToCreate`,
            align: `center`,
            render: (txt) =>
                txt ? (
                    <>
                        {txt} m<sup>3</sup>
                    </>
                ) : (
                    <>-</>
                ),
        },
        {
            title: `SAVĀKTS`,
            key: `collected`,
            align: `center`,
            render: (txt) =>
                txt ? (
                    <>
                        {txt} m<sup>3</sup>
                    </>
                ) : (
                    <>-</>
                ),
        },
    ],
    riskGroups: [
        {
            title: `NOSAUKUMS`,
            key: `name`,
            sorter: true,
        },
        {
            title: `APRAKSTS`,
            key: `description`,
            sorter: true,
            render: (txt, rec) => txt.replace(`{1}`, rec.config_1).replace(`{2}`, rec.config_2),
        },
    ],
    classifiers: [
        {
            title: `NOSAUKUMS`,
            key: `name`,
            sorter: true,
        },
    ],
    shipType: [
        {
            title: "NOSAUKUMS",
            key: "name",
            sorter: true,
        },
        {
            title: "IEKŠĒJAIS KODS",
            key: "internalCode",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts iekšējais kods"),
        },
        {
            title: "SKLOIS KODS",
            key: "skloisCode",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts SKLOIS kods"),
        },
    ],
    wasteType: [
        {
            title: "NOSAUKUMS",
            key: "name",
            sorter: true,
        },
        {
            title: "NOSAUKUMS (EN)",
            key: "nameEn",
            sorter: true,
            render: (value) =>
                value ? value : "Nav norādīts angliskais nosaukums",
        },
        {
            title: "KODS",
            key: "code",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts kods"),
        },
        {
            title: "KĀRTAS NUMURS",
            key: "orderNum",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts kārtas numurs"),
        },
        {
            title: "APRAKSTS",
            key: "description",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts apraksts"),
        },
        {
            title: "GRUPA",
            key: "group",
            sorter: true,
            render: (value) => (value ? value.name : "Nav grupa"),
        },
    ],
    wasteGroup: [
        {
            title: "NOSAUKUMS",
            key: "name",
            sorter: true,
        },
        {
            title: "NOSAUKUMS (EN)",
            key: "nameEn",
            sorter: true,
            render: (value) =>
                value ? value : "Nav norādīts angliskais nosaukums",
        },
        {
            title: "KĀRTAS NUMURS",
            key: "orderNum",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts kārtas numurs"),
        },
    ],
    port: [
        {
            title: "NOSAUKUMS",
            key: "name",
            sorter: true,
        },
        {
            title: "KODS",
            key: "code",
            sorter: true,
            render: (value) => (value ? value : "Nav norādīts kods"),
        },
    ],
    country: [
        {
            title: "NOSAUKUMS",
            key: "name",
            sorter: true,
        },
        {
            title: "KODS",
            key: "code",
            sorter: true,
        },
        {
            title: "EIROPAS SAVIENĪBAS VALSTS",
            key: "isInEU",
            sorter: true,
            render: (value) => (value ? `Jā` : "Nē"),
        },
    ],
    companies: [
        {
            title: `APUS NOSAUKUMS`,
            key: `organizationId`,
            sorter: true,
        },
        {
            title: `NOSAUKUMS`,
            key: `name`,
            sorter: true,
        },
        {
            title: `ĪSAIS NOSAUKUMS`,
            key: `shortName`,
            sorter: true,
        },
        {
            title: `E-PASTS`,
            key: `email`,
            sorter: true,
        },
        {
            title: `TEL. NUMURS`,
            key: `phone`,
            sorter: true,
        },
        {
            title: `MĀJASLAPA`,
            key: `website`,
            sorter: true,
        },
        {
            title: `PVN NUMURS`,
            key: `pvnNr`,
            sorter: true,
        },
        {
            title: `REĢISTRĀCIJAS NR.`,
            key: `regNr`,
            sorter: true,
        },
        {
            title: `TRANSPORTĒTĀJA LICENCE`,
            key: `transpLic`,
            sorter: true,
        },
        {
            title: `SAŅĒMĒJA LICENCE`,
            key: `receiverLic`,
            sorter: true,
        },
        {
            title: `SŪTĪŠANAS VEIDS`,
            key: `sendMethod`,
            sorter: true,
        },
        // {
        //     title: `SERTIFIKĀTS`,
        //     key: `sertifApus`,
        //     sorter: true,
        //     render: (value) => (value ? `Pievienots` : "Nav pievienots"),
        // },
    ]
};

export default tables;
