import React from "react";
import { ReactComponent as Ship } from "../images/shipGrey.svg";
import { ReactComponent as Arrow } from "../images/arrowGrey.svg";
import { ReactComponent as Paper } from "../images/paperGrey.svg";
import { ReactComponent as Statistics } from "../images/statisticsGrey.svg";
import { ReactComponent as Lightning } from "../images/lightningGrey.svg";

import AssignmentsPage from "../pages/Assignments";
import ShipVisitsPage from "../pages/ShipVisits";
import InvoicesPage from "../pages/Invoices";
import RisksPage from "../pages/Risks";
import RolesPage from "../pages/Roles";
import UsersPage from "../pages/Users";
import Statistika from "../Statistika";
import RiskGroupsPage from "../pages/RiskGroups";
import NewDeclaration from "../pages/NewDeclaration";
import EditDeclaration from "../pages/EditDeclaration";
import Companies from "../pages/Companies";
import ClassifiersPage from "../pages/Classifiers";
import ShipTypePage from "../pages/ShipType";
import WasteTypePage from "../pages/WasteType";
import WasteGroupPage from "../pages/WasteGroup";
import PortPage from "../pages/Port";
import CountryPage from "../pages/Country";
import OfflinePage from "../pages/Offline";
import SettingsPage from "../pages/Settings";
import MyCompany from "../pages/MyCompany";

import { UserOutlined, UserAddOutlined, UsergroupAddOutlined, SettingOutlined, ControlOutlined, BankOutlined } from '@ant-design/icons';

const routes = [
    {
        key: `offline`,
        title: `Offline`,
        icon: <Arrow />,
        page: OfflinePage,
        hidden: true,
    },
    {
        key: `ships`,
        title: `Kuģu vizītes`,
        icon: <Ship />,
        page: ShipVisitsPage,
        permissions: [`Skatīt savas kuģa vizītes`, `Skatīt visas kuģa vizītes`],
    },
    {
        key: `assignments`,
        title: `Norīkojumi`,
        icon: <Arrow />,
        page: AssignmentsPage,
        permissions: [
            `Skatīt savus norīkojumus`,
            `Skatīt sava uzņēmuma norīkojumus`,
            `Skatīt visus norīkojumus`,
        ],
    },
    {
        key: `declarations`,
        title: `Kvītis`,
        icon: <Paper />,
        page: InvoicesPage,
        permissions: [
            `Skatīt savas kvītis`,
            `Skatīt visas kvītis`,
            `Skatīt uzņēmuma kvītis`,
        ],
    },
    {
        key: `statistics`,
        title: `Statistika`,
        icon: <Statistics />,
        page: Statistika,
        permissions: [`Skatīt statistikas sadaļu`],
    },
    {
        key: `risks`,
        title: `Riski`,
        icon: <Lightning />,
        page: RisksPage,
        permissions: [`Skatīt risku sadaļu`],
    },
    {
        key: `user-provisioning`,
        title: `Lietotāju pārvaldība`,
        permissions: [
            `Skatīt savu lietotāju sarakstu`,
            `Skatīt visu lietotāju sarakstu`,
            "Veikt darbības lomu sadaļā",
        ],
        icon: <UserOutlined style={{ marginRight: 14, marginLeft: -4, color: `gray` }} />,
        sub: [
            {
                key: `users`,
                exact: false,
                title: `Lietotāji`,
                icon: <UserAddOutlined style={{ color: `gray` }} />,
                page: UsersPage,
                permissions: [
                    `Skatīt savu lietotāju sarakstu`,
                    `Skatīt visu lietotāju sarakstu`,
                ],
            },
            {
                key: `roles`,
                exact: false,
                title: `Lomas`,
                icon: <UsergroupAddOutlined style={{ color: `gray` }} />,
                page: RolesPage,
                permissions: ["Veikt darbības lomu sadaļā"],
            },
        ],
    },
    {
        key: `configuration`,
        title: `Konfigurācija`,
        // permissions: [`Skatīt savu lietotāju sarakstu`, `Skatīt visu lietotāju sarakstu`],
        icon: <SettingOutlined style={{ marginRight: 14, marginLeft: -4, color: `gray` }} />,
        sub: [
            {
                key: `configuration/risk-groups`,
                exact: false,
                title: `Risku grupas`,
                icon: <Lightning />,
                page: RiskGroupsPage,
                permissions: [
                    'Veikt darbības konfigurāciju sadaļā',
                ]
            },
            {
                key: `configuration/settings`,
                exact: false,
                title: `Iestatījumi`,
                icon: <SettingOutlined style={{  marginLeft: -4, color: `gray` }} />,
                page: SettingsPage,
                permissions: [
                    'Veikt darbības konfigurāciju sadaļā',
                ]
            },
        ],
        permissions: [
            'Veikt darbības konfigurāciju sadaļā',
        ]
    },
    {
        key: `classifiers/shipType`,
        title: `Kuģu tipi`,
        page: ShipTypePage,
        hidden: true,
        permissions: [`Veikt darbības klasifikatoru sadaļā`],
    },

    {
        key: `classifiers/wasteType`,
        title: `Atkritumu tipi`,
        page: WasteTypePage,
        hidden: true,
        permissions: [`Veikt darbības klasifikatoru sadaļā`],
    },
    {
        key: `classifiers/wasteGroup`,
        title: `Atkritumu grupas`,
        page: WasteGroupPage,
        hidden: true,
        permissions: [`Veikt darbības klasifikatoru sadaļā`],
    },
    {
        key: `classifiers/port`,
        title: `Ostas`,
        page: PortPage,
        hidden: true,
        permissions: [`Veikt darbības klasifikatoru sadaļā`],
    },
    {
        key: `classifiers/country`,
        title: `Valstis`,
        page: CountryPage,
        hidden: true,
        permissions: [`Veikt darbības klasifikatoru sadaļā`],
    },
    {
        key: `classifiers`,
        exact: true,
        title: `Klasifikatori`,
        icon: <ControlOutlined style={{  marginLeft: -4, color: `gray` }} />,
        page: ClassifiersPage,
        permissions: [
            `Veikt darbības klasifikatoru sadaļā`,
        ],
    },
    {
        key: `newdeclaration/:id`,
        title: `Jauna kvīts`,
        permissions: [
            "Veidot kvītis",
            "Veidot uzņēmuma kvītis",
            "Veidot savu norīkojumu kvītis",
        ],
        page: NewDeclaration,
        hidden: true,
    },
    {
        key: `editdeclaration/:id`,
        title: `Labot kvīti`,
        permissions: [
            "Labot kvītis",
            "Labot uzņēmuma kvītis",
            "Labot savu norīkojumu kvītis",
        ],
        icon: <Lightning />,
        page: EditDeclaration,
        hidden: true,
    },
    {
        key: `companies`,
        title: `Uzņēmumi`,
        permissions: [
            "Veikt darbības uzņēmumu sadaļā",
        ],
        icon: <BankOutlined style={{  marginLeft: -4, color: `gray` }} />,
        page: Companies,
    },
    {
        key: `myCompany`,
        title: `Mans uzņēmums`,
        permissions: [
            "Labot sava uzņēmuma datus",
        ],
        icon:  <BankOutlined style={{  marginLeft: -4, color: `gray` }} />,
        page: MyCompany,
    },    
];

export default routes;
