import React, { Component } from 'react';
import { Layout, Row, Col, Image, Typography, Form, Input, Checkbox, Button, message, notification } from 'antd';

import { user } from '../lib/User';
import kaupsLogo from "../images/kaupsLogo.svg";
import { required } from '../lib/BasicForm';
import { post } from '../lib/api';

import '../css/Login.css';

export const emailValidator = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value) return Promise.resolve();
        if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}$/) === null) {
            return Promise.reject(new Error(`Ievadiet derīgu e-pastu`));
        } else {
            return Promise.resolve();
        }
    },
});

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            waiting: false,
        };
    }

    authorize = async (data) => {
        this.setState({ waiting: true });

        await user.login(data.username, data.password, data.remember);
        this.props.parent.forceUpdate();
        await user.getResources();
        this.setState({ waiting: false });
    };

    recover = async (data) => {
        this.setState({ waiting: true });
        const response = await post(`sendResetPasswordEMail`, data);
        if (response.ok && response.status === 200 && response.data.data!==0) {
            message.success(response.data?.message);
            this.setState({ waiting: false, recover: false });
            return;
        } else if (response.data?.error) {
            notification.error({
                duration: 0,
                message: response.data?.title,
                description: response.data?.error,
            });
        } else {
            notification.error({
                duration: 0,
                message: `500 Servera kļūda`,
                description: `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
            });
        }
        this.setState({ waiting: false });
    };

    render() {
        const { state } = this;

        if (state.recover) {
            return (
                <Layout style={{ minHeight: "100vh" }}>
                    <Layout.Content style={{ padding: "0 50px", margin: "23px 0px 0px 0px" }}>
                        <Row justify="center">
                            <Col
                                span="8"
                                className="login-box"
                            >
                                <Row>
                                    <Col
                                        xs={0}
                                        sm={0}
                                        md={10}
                                        className="login-image"
                                    >
                                        <Image
                                            width="100%"
                                            height="100%"
                                            preview={false}
                                            src={kaupsLogo}
                                            style={{
                                                transform: `translateY(-50%)`,
                                                position: `absolute`,
                                                top: `50%`,
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} md={14} style={{ minHeight: `30vh`, padding: 30 }}>
                                        <Typography.Title level={5} style={{ textAlign: `center` }}>PAROLES ATIESITATĪŠANA</Typography.Title>
                                        <br />
                                        <Form onFinish={this.recover}>
                                            <Form.Item name="email" rules={[required, emailValidator]}>
                                                <Input placeholder="Norādiet e-pastu" />
                                            </Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={state.waiting}
                                                disabled={state.waiting}
                                                style={{ width: `100%` }}
                                            >
                                                Nosūtīt e-pastu
                                            </Button>
                                        </Form>
                                        <Typography.Link
                                            style={{ display: `block`, margin: `10px auto`, textAlign: `center` }}
                                            onClick={() => this.setState({ recover: false })}
                                        >
                                            Atpakaļ
                                        </Typography.Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Layout.Content>
                </Layout>
            );
        }

        return (
            <Layout style={{ minHeight: "100vh" }}>
                <Layout.Content style={{ padding: "0 50px", margin: "23px 0px 0px 0px" }}>
                    <Row justify="center">
                        <Col
                            span="8"
                            className="login-box"
                        >
                            <Row>
                                <Col
                                    xs={0}
                                    sm={0}
                                    md={10}
                                    className="login-image"
                                >
                                    <Image
                                        width="100%"
                                        height="100%"
                                        preview={false}
                                        src={kaupsLogo}
                                        style={{
                                            transform: `translateY(-50%)`,
                                            position: `absolute`,
                                            top: `50%`,
                                        }}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={14} style={{ minHeight: `30vh`, padding: 30 }}>
                                    <Typography.Title level={5} style={{ textAlign: `center` }}>LIETOTĀJA AUTORIZĀCIJA</Typography.Title>
                                    <br />
                                    <Form onFinish={this.authorize}>
                                        <Form.Item name="username" rules={[{ required: true, message: 'Norādiet lietotājvārdu.' }]}>
                                            <Input placeholder="Lietotājvārds" />
                                        </Form.Item>
                                        <Form.Item name="password" rules={[{ required: true, message: 'Norādiet paroli.' }]}>
                                            <Input.Password placeholder="Parole" />
                                        </Form.Item>
                                        <Form.Item name="remember" valuePropName="checked" initialValue={true}>
                                            <Checkbox style={{ userSelect: `none` }}>Atcerēties mani</Checkbox>
                                        </Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={state.waiting}
                                            disabled={state.waiting}
                                            style={{ width: `100%` }}
                                        >
                                            Ienākt
                                        </Button>
                                    </Form>
                                    <Typography.Link
                                        style={{ display: `block`, margin: `10px auto`, textAlign: `center` }}
                                        onClick={() => this.setState({ recover: true })}
                                    >
                                        Aizmirsi paroli?
                                    </Typography.Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout >
        );
    }
}

export default LoginPage;
