import React, { Component } from "react";
import { Menu, Typography, Row, Col, Button, Input, Dropdown, message, Empty } from "antd";
import { MenuFoldOutlined, SettingOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Link, matchPath, withRouter } from "react-router-dom";

import routes from "./lib/routes";
import { can, user } from "./lib/User";

import kaupsLogo from "./images/kaupsLogo.svg";
import bellIcon from "./images/bellIcon.svg";
import trashBin from "./images/trashBin.svg";
import search from "./images/search.svg";

import "./App.css";
import Search from "./lib/widgets/Search";
import { destroy } from "./lib/api";

const { Text } = Typography;

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: [],
    };
  }

  clear = async () => {
    let success = true;
    for (const not of user.notifications) {
      const response = await destroy(`notifications`, not.id);
      success &= (response.ok && response.status === 200);
    }

    if (success) {
      message.success(`Paziņojumi notīrīti.`)
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }
    await user.sync();
  };

  hide = async (e, id) => {
    e.stopPropagation();
    const response = await destroy(`notifications`, id);
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data.message);
    } else if (response.data && response.data.error) {
      message.error(response.data.error);
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }
    await user.sync();
  };

  open = (url) => {
    if (url) {
      this.props.history.push(url);
      this.props.parent.forceUpdate();
    }
    this.collapse();
  };

  logout = async () => {
    this.collapse();
    await user.logout();
    this.props.parent.forceUpdate();
  };

  collapse = () => {
    this.props.parent.setState({ collapsed: true });
    document.body.style.overflowY = `auto`;
  }

  render() {
    const { state } = this;
    const { isMobile, parent } = this.props;

    const defaultPage = `ships`;
    const openKeys = [];
    const selectedKeys = [];

    for (const route of routes) {
      if (route.sub) {
        let flag = false;
        for (const sub of route.sub) {
          if (matchPath(window.location.pathname, { path: `/${sub.key}`, strict: sub.strict, exact: sub.exact })) {
            selectedKeys.push(sub.key);
            flag = true;
          }
        }

        if (flag) {
          openKeys.push(route.key);
        }
      } else {
        if (matchPath(window.location.pathname, { path: `/${route.key}`, strict: route.strict, exact: route.exact })) {
          selectedKeys.push(route.key);
        }
      }
    }

    if (selectedKeys.length === 0) {
      selectedKeys.push(defaultPage);
    }

    return (
      <>
        {
          isMobile && (
            <>
              <Row gutter={10} style={{ width: `100%`, color: `white`, paddingLeft: 15 }}>
                <Col span={20} style={{ display: `flex` }}>
                  <div className="vcenter">
                    <img src={kaupsLogo} alt="" style={{ width: 50, height: 50 }} />
                  </div>
                  <Text
                    strong
                    style={{
                      top: 15,
                      left: 60,
                      fontSize: 22,
                      color: `white`,
                      display: `block`,
                      lineHeight: `22px`,
                      position: `absolute`,
                      fontFamily: `Arial`,
                    }}
                  >
                    KAUPS
                </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: `white`,
                      display: `block`,
                      lineHeight: `14px`,
                      fontFamily: `Arial`,
                      padding: `40px 0 10px 5px`,
                      textTransform: `uppercase`,
                    }}
                  >
                    {user.roles.length ? user.roles.join(`, `) : `Lietotājs`}
                  </Text>
                </Col>
                <Col span={4}>
                  <div className="vcenter" style={{ float: `right` }}>
                    <Button
                      type="text"
                      onClick={this.collapse}
                      style={{ textTransform: `uppercase` }}
                    >
                      <MenuFoldOutlined style={{ color: `white`, fontSize: `2em` }} />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col sm={12} span={24} style={{ padding: 10 }}>
                  <div style={{ float: `right`, display: `flex`, height: `100%`, width: `100%` }}>
                    <div className="vcenter" style={{ width: `100%` }}>
                      <Search
                        source="ports"
                        style={{ width: `100%` }}
                        placeholder="Norādiet ostu"
                        value={this.props.parent.state.port}
                        onChange={port => {
                          this.collapse();
                          this.props.parent.setState({ port });
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm={12} span={24} style={{ padding: 10 }}>
                  <div style={{ float: `right`, display: `flex`, height: `100%`, width: `100%` }}>
                    <div className="vcenter" style={{ width: `100%` }}>
                      <Input
                        bordered={false}
                        ref={this.searchBar}
                        className="searchDivInput"
                        style={{ color: `white` }}
                        onPressEnter={parent.search}
                        value={parent.state.searchBarVal}
                        placeholder="IMO vai kvīts numurs..."
                        onChange={(e) => parent.setState({ searchBarVal: e.target.value })}
                      />
                    </div>
                    <div className="vcenter">
                      <Button type="text" onClick={() => {
                        this.collapse();
                        parent.search();
                      }}>
                        <img src={search} alt="" />
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={8}>
                  <div
                    className="vcenter"
                    style={{ alignItems: `center`, height: 50 }}
                    onClick={() => {
                      this.collapse();
                      this.props.history.push(`/profile`);
                    }}
                  >
                    <SettingOutlined style={{ fontSize: 20, color: `white` }} />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="vcenter" style={{ alignItems: `center`, height: 50 }}>
                    <Dropdown
                      trigger={['click']}
                      visible={state.visible ?? false}
                      onVisibleChange={(visible) => this.setState({ visible })}
                      overlay={
                        !!user.notifications?.length ? (
                          <Menu>
                            {
                              user.notifications.map(not => (
                                <Menu.Item onClick={() => this.open(not.url)}>
                                  <div className="menuItemWidth">
                                    <div className="notificationMenuItemLimiter">
                                      <div className="notificationsTrashAlign">
                                        <Text>{not.title}</Text>
                                        <img src={trashBin} alt="" onClick={(e) => this.hide(e, not.id)} />
                                      </div>
                                      <Text
                                        type="secondary"
                                        className="notificationDescription"
                                      >
                                        {not.description}
                                      </Text>
                                    </div>
                                  </div>
                                </Menu.Item>
                              ))
                            }
                            <Menu.Item className="clearNotificationsItem" onClick={() => this.clear()}>
                              <div className="menuItemWidth">
                                <Text type="secondary">NOTĪRĪT PAZIŅOJUMUS</Text>
                              </div>
                            </Menu.Item>
                          </Menu>
                        ) : (
                            <div
                              style={{ padding: `5px 30px`, minWidth: 180 }}
                              className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical"
                            >
                              <Empty
                                description="Nav notifikāciju"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              />
                            </div>
                          )
                      }
                      className="notificationsDropdown"
                      placement="bottomRight"
                      arrow
                      overlayStyle={{ zIndex: 99999, top: 10 }}
                    >
                      <div>
                        <img src={bellIcon} alt="" style={{ width: 20, marginTop: 23 }} />
                        {
                          !!user.notifications?.length && (
                            <div className="notificationCount" style={{ bottom: 35 }}>
                              <p>{user.notifications?.length}</p>
                            </div>
                          )
                        }
                      </div>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="vcenter" style={{ alignItems: `center`, height: 50 }} onClick={this.logout}>
                    <LogoutOutlined style={{ fontSize: 20, color: `white` }} />
                  </div>
                </Col>
              </Row>
            </>
          )
        }
        <Menu
          mode="inline"
          selectedKeys={selectedKeys}
          theme={isMobile ? `dark` : `light`}
          openKeys={[...state.open, ...openKeys]}
          onOpenChange={(open) => {
            this.setState({ open });
          }}
        >
          {
            isMobile && (
              <Menu.SubMenu
                icon={<UserOutlined />}
                title={`${user.name} ${user.surname}`}
                style={{ userSelect: `none`, textTransform: `uppercase` }}
              >
                <Menu.Item style={{ userSelect: `none` }}>
                  <Link to="/profile"
                    style={{ textTransform: `uppercase` }}
                    onClick={() => parent.setState({ collapsed: true })}
                  >
                    IESTATĪJUMI
                  </Link>
                </Menu.Item>
                <Menu.Item style={{ userSelect: `none` }}>
                  VALODA
                </Menu.Item>
                <Menu.Item
                  onClick={this.logout}
                  icon={<LogoutOutlined />}
                  style={{ userSelect: `none` }}
                >
                  ATSLĒGTIES
                </Menu.Item>
              </Menu.SubMenu>
            )
          }
          {
            routes.map(route => {
              if (!route.icon) {
                return <></>;
              }

              let accessGranted = true;
              if (route.permissions) {
                accessGranted = false;
                for (const action of route.permissions) {
                  accessGranted |= can(action);
                }
              }

              if (!accessGranted || route.hidden) {
                return <></>;
              }

              return (
                route.sub ? (
                  <Menu.SubMenu key={route.key} icon={route.icon} title={route.title} style={{ userSelect: `none`, textTransform: `uppercase` }}>
                    {
                      route.sub.map(sub => {
                        if (sub.permissions) {
                          accessGranted = false;
                          for (const action of sub.permissions) {
                            accessGranted |= can(action);
                          }
                        }

                        if (!accessGranted) {
                          return <></>;
                        }

                        return (
                          <Menu.Item key={sub.key} icon={sub.icon} style={{ userSelect: `none` }}>
                            <Link to={`/${sub.key}`} style={{ textTransform: `uppercase` }} onClick={() => {
                              if (sub.ref && sub.ref.current) {
                                if (sub.ref.current.goToDefaultPage) {
                                  sub.ref.current.goToDefaultPage();
                                }
                                sub.ref.current.forceUpdate();
                              }
                              document.body.style.overflowY = `auto`;
                              parent.setState({ collapsed: isMobile });
                            }}>
                              {sub.title}
                            </Link>
                          </Menu.Item>
                        );
                      })
                    }
                  </Menu.SubMenu>
                ) : (
                    <Menu.Item key={route.key} icon={route.icon} style={{ userSelect: `none` }}>
                      <Link
                        to={`/${route.key}`}
                        style={{ textTransform: `uppercase` }}
                        onClick={() => {
                          document.body.style.overflowY = `auto`;
                          parent.setState({ collapsed: isMobile })
                        }}
                      >
                        {route.title}
                      </Link>
                    </Menu.Item>
                  )
              )
            })
          }
        </Menu>
      </>
    );
  }
}

export default withRouter(Sidebar);
