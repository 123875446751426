import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Collapse,
  Input,
  Checkbox,
  Alert,
  message,
  Spin,
  Cascader,
  Form,
  notification,
  Card,
  InputNumber,
} from 'antd';
import { Picker } from 'antd-mobile';
import { DeleteTwoTone } from '@ant-design/icons';
import moment from 'moment';

import checkmarkDeclaration from '../images/checkmarkDeclaration.svg';

import { acquire, post } from '../lib/api';
import { emailValidator } from './Login';
import NoAccess from './NoAccess';
import SimpleTable from '../lib/widgets/SimpleTable';
import Search from '../lib/widgets/Search';
import { required } from '../lib/BasicForm';

import '../App.css';
import '../css/Content.css';
import '../css/DeclarationTable.css';

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

class EditDeclaration extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.cascader = [];

    this.state = {
      counter: 1000,
      loading: true,
      pending: false,
      declaration: {},
      email: undefined,
      errorTitle: undefined,
      errorMessage: undefined,
      isError: false,
      wasteGroups: [],
      openCascader: false,
      selectedGroup: [null, null],
    };
  }

  load = async () => {
    const id = this.props.match.params.id;
    let response = await acquire(`declarations/${id}`, null, null);
    if (response.ok) {
      this.setState({
        loading: false,
        isError: false,
        errorTitle: undefined,
        errorMessage: undefined,
        sendEMail: response?.data?.data?.sendEMail ?? true,
        declaration: { ...response.data.data },
      });
    } else {
      this.setState({
        isError: true,
        loading: false,
        declaration: {},
        sendEMail: true,
        errorTitle: response.data?.title ?? `500 Sistēmas kļūda`,
        errorMessage: response.data?.error ?? `Notikusi sistēmas kļūda, mēģiniet vēlreiz.`,
      });

      notification.error({
        duration: 0,
        message: response.data?.title ?? `500 Sistēmas kļūda`,
        description: response.data?.error ?? `Notikusi sistēmas kļūda, mēģiniet vēlreiz.`,
      });

      return;
    }

    response = await acquire(`cascaderWaste`, null, null);
    if (response.ok) {
      this.setState({
        isError: false,
        wasteGroups: response.data.data.slice(),
        errorTitle: undefined,
        errorMessage: undefined,
      });
    } else {
      this.setState({
        isError: true,
        wasteGroups: [],
        errorTitle: response.data?.title ?? `500 Sistēmas kļūda`,
        errorMessage: response.data?.error ?? `Notikusi sistēmas kļūda, mēģiniet vēlreiz.`,
      });

      notification.error({
        duration: 0,
        message: response.data?.title ?? `500 Sistēmas kļūda`,
        description: response.data?.error ?? `Notikusi sistēmas kļūda, mēģiniet vēlreiz.`,
      });
    }
  };

  addWaste = (values) => {
    const { state } = this;

    let wasteType = null;
    let wasteGroup = null;
    for (const group of state.wasteGroups) {
      if (group.id === values.type[0]) {
        wasteGroup = { ...group };
        break;
      }
    }

    for (const type of wasteGroup.types ?? []) {
      if (type.id === values.type[1]) {
        wasteType = { ...type };
        break;
      }
    }

    const declaration = { ...state.declaration };
    declaration.waste.push({
      id: state.counter,
      remainder: null,
      collected: parseFloat(values?.amount ?? 0).toFixed(4),
      canDelete: true,
      type: {
        id: wasteType.id,
        name: wasteType.name,
        group: {
          id: wasteGroup.id,
          name: wasteGroup.name,
        }
      },
    });

    this.formRef.current.resetFields();
    this.setState(old => ({ declaration, selectedGroup: [null, null], counter: old.counter + 1 }));
  };

  removeWaste = (id) => {
    const { state } = this;

    const declaration = { ...state.declaration };
    const waste = declaration.waste.filter((w) => w.id !== id);
    declaration.waste = waste.slice();

    this.setState({ declaration });
  };

  setValue = (key, value) => {
    const declaration = { ...this.state.declaration };
    for (const i in declaration?.waste ?? []) {
      if (declaration.waste[i]?.id === key) {
        declaration.waste[i].collected = value?.toFixed(4) ?? 0;
        break;
      }
    }

    this.setState({ declaration });
  };

  submit = async (data) => {
    const { state } = this;
    this.setState({ pending: true });

    const body = {
      ...data,
      canSendEMail: state.canSendEMail,
      waste: [],
    };

    for (const row of state?.declaration?.waste ?? []) {
      body.waste.push({
        id: row?.id ?? null,
        amount: row?.collected ?? 0,
        canDelete: row?.canDelete ?? false,
        type: {
          id: row?.type?.id ?? null,
          name: row?.type?.name ?? `Bez nosaukuma`,
          group: {
            id: row?.type?.group?.id ?? null,
            name: row?.type?.group?.name ?? `Negrupēts`,
          }
        }
      });
    }

    const response = await post(`declarations`, body, state.declaration.id);
    if (response.ok) {
      message.success(response.data.message ?? `Kvīts saglabāta.`);
    } else {
      notification.error({
        duration: 0,
        message: response?.data?.title ?? `Kļūda`,
        description: response?.data?.error ?? `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
      });
    }

    this.setState({ pending: false });
  };

  render() {
    const { state } = this;
    const { declaration } = this.state;
    const { isMobile } = this.props;

    if (state.isError) {
      return <NoAccess title={state.errorTitle} message={state.errorMessage} />;
    }

    if (state.loading) {
      this.load();
      return (
        <Spin
          size="large"
          style={{
            top: `50%`,
            left: `50%`,
            zIndex: 9999,
            position: `absolute`,
            transform: `translate(-50%, -50%)`,
          }}
        />
      );
    }

    const data = [];
    const unknown = {
      key: `unknown`,
      name: `Negrupētie`,
      collected: 0,
      children: [],
    };

    const usedTypes = [];
    for (const row of declaration?.waste ?? []) {
      const groupId = row?.type?.group?.id ?? null;
      if (groupId === null) {
        unknown.collected += row?.collected ?? 0;
        unknown.children.push({
          key: row.id,
          name: row?.type?.name ?? `Nav nosaukuma`,
          collected: row?.collected ?? 0,
          apusClassId: row?.apusClassId ?? undefined,
          isChild: true,
          canDelete: row?.canDelete ?? false,
        });
      } else {
        if (!data[groupId]) {
          data[groupId] = {};
          data[groupId].key = groupId;
          data[groupId].name = row.type?.group?.name ?? `Nav nosaukuma`;
          data[groupId].collected = 0;
          data[groupId].children = [];
        }

        data[groupId].collected += parseFloat(row?.collected ?? 0);
        data[groupId].children.push({
          key: row.id,
          name: row?.type?.name ?? `Nav nosaukuma`,
          collected: row?.collected ?? 0,
          apusClassId: row?.apusClassId ?? undefined,
          isChild: true,
          canDelete: row?.canDelete ?? false,
        });
      }

      usedTypes.push(row.type?.id);
    }

    if (unknown.children.length > 0) {
      data.push({ ...unknown });
    }

    this.cascader = [];
    for (const group of state.wasteGroups) {
      const _cG = {
        value: group.id,
        label: group.name,
        children: [],
      };

      for (const type of group.types) {
        if (!usedTypes.includes(type.id)) {
          _cG.children.push({
            value: type.id,
            label: type.name,
          });
        }
      }

      if (_cG.children.length > 0) {
        this.cascader.push({ ..._cG });
      }
    }

    let wasteType = null;
    let wasteGroup = null;
    for (const group of state.wasteGroups) {
      if (group.id === state.selectedGroup[0]) {
        wasteGroup = { ...group };
        break;
      }
    }

    for (const type of wasteGroup?.types ?? []) {
      if (type.id === state.selectedGroup[1]) {
        wasteType = { ...type };
        break;
      }
    }

    return (
      <Content style={{ padding:isMobile?"0px 10px": "9px 35px", minHeight: 280 }}>
        <Card
          bordered={false}
          title={
            <Text type="secondary" style={{ fontSize: 15 }}>
              Labot kvīti
            </Text>
          }
          bodyStyle={{ minHeight: 300, position: `relative` }}
        >
          <Collapse expandIcon="" activeKey="1" className="contentCollapse">
            <Panel header={(
              <>
                <Text strong style={{ marginRight: 20 }}>
                  {`IMO${declaration?.assignment?.ship_visit?.ship?.imo ?? `000000`}`}
                </Text>
                <Text type="secondary" strong style={{ marginRight: 20 }}>
                  {declaration?.assignment?.ship_visit?.ship?.name ?? <em>Nezināms</em>}
                </Text>
              </>
            )}
              showArrow={false}
              key="1"
            >
              <div className="newDeclarationFirstRow">
                <Text>Kvīts datums: </Text>
                <Text strong>{moment(declaration?.created_at).format(`DD.MM.YYYY HH:mm`)}</Text>
                <br />
                <Text>Šoferis: </Text>
                <Text strong>
                  {`${declaration?.assignment?.driver?.name?.toUpperCase() ?? `Anonīms`} 
                  ${declaration?.assignment?.driver?.surname?.toUpperCase() ?? ``} 
                  ("${declaration?.assignment?.driver?.company?.name ?? `Nav norādīts`}")`}
                </Text>
              </div>
              <SimpleTable
                className="newDeclarationTable"
                indentSize="0"
                columns={[
                  {
                    title: ``,
                    key: `name`,
                    sorter: false,
                    render: (txt, row) => (row.isChild && row.canDelete) ?
                      <>
                        <Button
                          ghost
                          disabled={state.pending}
                          onClick={() => this.removeWaste(row.key)}
                          style={{ padding: `0 5px`, margin: `0 4px 0 -30px` }}
                        >
                          <DeleteTwoTone twoToneColor="#f5222d" />
                        </Button>
                        {txt}
                      </> :
                      <>{txt}</>,
                  },
                  // {
                  //   title: `APUS KLASE`,
                  //   key: `apusClassId`,
                  //   sorter: false,
                  //   render: (txt, row) => row.isChild ? <>Child</> : <></>,
                  // },
                  {
                    title: `FAKTISKI SAVĀKTS`,
                    key: `collected`,
                    align: `right`,
                    sorter: {
                      compare: (a, b) => a.collected - b.collected,
                    },
                    render: (txt, row) => (
                      row.isChild ? (
                        <InputNumber
                          min={0}
                          key={`input-${row.id}`}
                          className="amount"
                          placeholder="0.0000 m3"
                          disabled={state.pending}
                          value={row?.collected ?? undefined}
                          onChange={(val) => this.setValue(row.key, val)}
                          style={{ float: `right`, minWidth: 110 }}
                        />
                      ) : (
                        <>{row.collected?.toFixed(4) ?? 0} m<sup>3</sup></>
                      )
                    ),
                  },
                ]}
                dataSource={data}
                pagination={false}
                style={{ marginTop: 22 }}
              />
              <Card
                bordered={false}
                title={<strong>PAPILDUS SAVĀKTS</strong>}
                bodyStyle={{ position: `relative` }}
              >
                <Form
                  layout="inline"
                  className="addWasteForm"
                  onFinish={this.addWaste}
                  ref={this.formRef}
                >
                  <Row gutter={10} style={{ width: `100%` }}>
                    <Col span={12}>
                      <Form.Item
                        name="type"
                        rules={[required]}
                      >
                        {
                          !isMobile ? (
                            <Cascader
                              className="additionalnameInput"
                              placeholder="Norādiet atrkitumi veidu"
                              disabled={state.pending}
                              options={this.cascader}
                            />
                          ) : (
                              <Picker
                                cols={2}
                                data={this.cascader}
                                okText="Saglabāt"
                                dismissText="Notīrīt"
                                disabled={state.pending}
                                onOk={(values) => this.setState({ selectedGroup: values })}
                                onDismiss={() => {
                                  this.setState({ selectedGroup: [null, null] });
                                  this.formRef.current.setFieldsValue({ type: undefined });
                                }}
                              // visible={state.openCascader}
                              >
                                <Input
                                  value={wasteType ? `${wasteGroup?.name ?? `Negrupēts`} / ${wasteType?.name ?? `Bez nosaukuma`}` : undefined}
                                  placeholder="Norādiet atrkitumi veidu"
                                  readOnly={true}
                                />
                              </Picker>
                            )
                        }
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div style={{ float: `right`, display: `flex` }}>
                        <Form.Item
                          name="amount"
                          rules={[required]}
                          normalize={(val) => val?.toFixed(4) ?? undefined}
                          style={{ minWidth: 172 }}
                        >
                          <InputNumber
                            min={0}
                            className="amount"
                            placeholder="0.0000 m3"
                            disabled={state.pending}
                            style={{ float: `right`, minWidth: 110 }}
                          />
                        </Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={state.pending}
                          className="addWasteButton"
                        >
                          PIEVIENOT
                          </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card>
              <Card
                bordered={false}
                title={<strong>KVĪTS INFORMĀCIJA</strong>}
                bodyStyle={{ minHeight: 200, position: `relative` }}
              >
                <Form
                  onFinish={this.submit}
                  labelCol={{ xl: 10, span: 24 }}
                  wrapperCol={{ xl: 14, span: 24 }}
                  initialValues={{
                    transport: declaration.transport,
                    carrierLicense: declaration.carrierLicense,
                    receiverLicense: declaration.receiverLicense,
                    email: declaration.email,
                  }}
                >
                  <Form.Item
                    name="transport"
                    rules={[required]}
                    label="Transporta veids"
                  >
                    <Search
                      disabled={state.pending}
                      source="apusTransportTypes"
                      placeholder="Norādiet transporta veidu"
                      style={{ maxWidth: 350 }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="receiverLicense"
                    rules={[required]}
                    label="Saņēmēja licence"
                  >
                    <Search
                      disabled={state.pending}
                      source="apusReceiverLicence"
                      extraParameters={[
                        {
                          key: `organizationId`,
                          value: declaration?.assignment?.dispatcher?.company?.organizationId ?? null,
                        },
                      ]}
                      placeholder="Norādiet saņēmēja licenci"
                      style={{ maxWidth: 350 }}
                      options={declaration?.__receiverLicense}
                    />
                  </Form.Item>
                  <Form.Item
                    name="carrierLicense"
                    rules={[required]}
                    label="Transportētāja licence"
                  >
                    <Search
                      disabled={state.pending}
                      source="apusTranspLicence"
                      extraParameters={[
                        {
                          key: `organizationId`,
                          value: declaration?.assignment?.driver?.company?.organizationId ?? null,
                        },
                      ]}
                      placeholder="Norādiet transportētāja licenci"
                      style={{ maxWidth: 350 }}
                      options={declaration?.__carrierLicence}
                    />
                  </Form.Item>
                  <Form.Item
                    name="reason"
                    rules={[required]}
                    label="Labošanas iemesls"
                  >
                    <Input.TextArea
                      disabled={state.pending}
                      style={{ maxWidth: 350, minHeight: 150 }}
                      placeholder={`Norādiet labošanas iemselu (piem. "Aizpildīju trūkstošos laukus.")`}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[emailValidator]}
                    label={
                      <Checkbox
                        checked={state.sendEMail}
                        disabled={state.pending}
                        className="form-label-checkbox"
                        onChange={(e) => this.setState({ sendEMail: e.target.checked })}
                      >
                        Kvīts PDF kopiju nosūtīt uz
                    </Checkbox>
                    }
                  >
                    <Input
                      disabled={state.pending}
                      style={{ maxWidth: 350 }}
                      placeholder="Norādiet e-pastu"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={state.pending}
                    disabled={state.pending}
                    className="acceptDeclarationButton"
                    style={{ margin: 0, float: `right` }}
                    icon={state.pending ? (
                      <></>
                    ) : (
                        <img
                          src={checkmarkDeclaration}
                          className="checkmarkAccept"
                          alt=""
                        />
                      )}
                  >
                    SAGLABĀT
                  </Button>
                </Form>
              </Card>
            </Panel>
          </Collapse>
          <Alert
            className="internetAlert"
            message="Ja interneta savienojums nav pieejams, kvīts tiks saglabāta ierīcē un nosūtīta vēlāk."
            type="info"
            showIcon
          />
        </Card>
      </Content >
    );
  }
}

export default withRouter(EditDeclaration);
