import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  Collapse,
  Button,
  Typography,
  Layout,
  Tabs,
  message,
  Form,
  Row,
  //   Col,
  Pagination,
  Card,
} from "antd";
import moment from "moment";

import { post } from "../lib/api";
import Search from "../lib/widgets/Search";
import CustomForm from "../lib/CustomForm";

import paperclip from "../images/paperclip.png";
import { ReactComponent as ArrowIcon } from "../images/arrowIcon.svg";
import greyCross from "../images/greyCross.svg";
// import greenCheckmark from "../images/greenCheckmark.svg";
import { required } from "../lib/BasicForm";
import SimpleTable from "../lib/widgets/SimpleTable";
import tables from "../lib/tables";

import VisitDate from "../lib/widgets/VisitDate";
import VisitStatus from "../lib/widgets/VisitStatus";

import { FilePdfOutlined } from "@ant-design/icons";

class ShipVisitsPage extends CustomForm {
  location = `/ships`;
  source = `visits`;
  driverForm = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showSort: "4",
    }
  }

  getExtraProperties = () => {
    //filtrēšanas noklusējuma vērtība 4 == rādīt visus
    const extraProps = [{ key: `showSort`, value: this.state.showSort ?? "4" }];

    if (this.props.port) {
      extraProps.push({ key: `port`, value: this.props.port });
    }
    if (this.props.searchBarVal) {
      extraProps.push({ key: `searchBarVal`, value: this.props.searchBarVal });
    }

    return extraProps;
  };

  assignDriver = async (data) => {
    const response = await post(`assignments`, data);
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data.message);
      this.driverForm.current.resetFields();
    } else if (response.data && response.data.error) {
      message.error(response.data.error);
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }

    this.load();
  };

  renderItems = (data) => {
    const { state } = this;
    const { isMobile } = this.props;

    return (
      <>
        <Layout.Content style={{ padding: isMobile ? "0px 10px" : "9px 35px", minHeight: 280 }}>
          <Card
            bordered={false}
            title={
              <Typography.Text type="secondary" style={{ fontSize: 15 }}>
                Kuģu saraksts ostā
              </Typography.Text>
            }
            extra={
              !isMobile && (
                <div style={{whiteSpace:"nowrap"}}>
                  <Button
                    float="right"
                    style={{ marginRight: "19px", borderRadius: "5px" }}
                    type={state.showSort === "1" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "1",
                        loading: (old.showSort ?? "4") !== "1",
                      }))
                    }
                  >
                    GAIDĀMIE
                  </Button>

                  <Button
                    float="right"
                    style={{ marginRight: "19px", borderRadius: "5px" }}
                    type={state.showSort === "2" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "2",
                        loading: (old.showSort ?? "4") !== "2",
                      }))
                    }
                  >
                    ATRODAS OSTĀ
                  </Button>

                  <Button
                    style={{ borderRadius: "5px" }}
                    type={state.showSort === "3" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "3",
                        loading: (old.showSort ?? "4") !== "3",
                      }))
                    }
                  >
                    PAMETUŠIE OSTU
                  </Button>

                  <Button
                    float="right"
                    style={{ marginLeft: "19px", borderRadius: "5px" }}
                    type={state.showSort === "4" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "4",
                        loading: (old.showSort ?? "4") !== "4",
                      }))
                    }
                  >
                    RĀDĪT VISUS
                  </Button>
                </div>
              )
            }
          >
            {!!isMobile && (
              <>
                <div style={{ width: "100%", float: `right`, display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
                  <Button
                    float="right"
                    style={{ borderRadius: "5px", width: "max(100%,140px)", marginBottom: "5px" }}
                    type={state.showSort === "1" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "1",
                        loading: (old.showSort ?? "4") !== "1",
                      }))
                    }
                  >
                    GAIDĀMIE
                  </Button>

                  <Button
                    float="right"
                    style={{ borderRadius: "5px", width: "max(100%,140px)", marginBottom: "5px" }}
                    type={state.showSort === "2" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "2",
                        loading: (old.showSort ?? "4") !== "2",
                      }))
                    }
                  >
                    ATRODAS OSTĀ
                  </Button>

                  <Button
                    style={{ borderRadius: "5px", width: "max(100%,140px)", marginBottom: "5px" }}
                    type={state.showSort === "3" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "3",
                        loading: (old.showSort ?? "4") !== "3",
                      }))
                    }
                  >
                    PAMETUŠIE OSTU
                  </Button>

                  <Button
                    float="right"
                    style={{ borderRadius: "5px", width: "max(100%,140px)", marginBottom: "5px" }}
                    type={state.showSort === "4" ? `primary` : `default`}
                    onClick={() =>
                      this.setState((old) => ({
                        showSort: "4",
                        loading: (old.showSort ?? "4") !== "4",
                      }))
                    }
                  >
                    RĀDĪT VISUS
                  </Button>
                </div>
              </>
            )}
            <div style={isMobile ? { position: "relative", marginTop: "160px" } : {}}>
              {data.map((item, i) => {
                let sum = 0;
                for (const waste of item?.waste) {
                  sum += parseFloat(waste.forDisposal);
                }

                return (
                  <Collapse
                    expandIconPosition="right"
                    className="contentCollapse"
                    key={`item-${i}`}
                  >
                    <Collapse.Panel
                      showArrow={false}
                      header={
                        <div className="shipVisitPanelHeader" style={isMobile ? { flexDirection: "column" } : {}}>
                          <Typography.Text strong style={{ marginRight: "20px" }}>
                            "{item?.ship?.name ?? <em>Nezināms</em>}"
                        </Typography.Text>
                          <VisitDate {...item} />
                          <br />
                          <VisitStatus {...item} style={{ marginRight: "10px" }} />
                          <br />
                          <Button
                            className="previewVisitPdfButton"
                            href={"/api/v1/visits/" + item.id + "/previewPdf"}
                            target="_blank"
                            danger
                          >
                            Atkritumu kvīts PDF
                          <FilePdfOutlined />
                          </Button>
                        </div>
                      }
                    >
                      <Layout.Content>
                        {!isMobile ?
                          <>
                            <div className="firstLevel">
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>VIZĪTES ID:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>{item.visitCode ?? <em>Nav</em>}</span>
                                </div>
                              </div>
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>TERMINĀLIS:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>{item.terminal?.name ?? <em>Nav</em>}</span>
                                </div>
                              </div>
                            </div>
                            <div className="firstLevel">
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>IMO NUMURS:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>{item?.ship?.imo ?? <em>Nav</em>}</span>
                                </div>
                              </div>
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>DEKLARĒTĀJS:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>
                                    {item?.company?.name ?? <em>Nezināms</em>}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="firstLevel">
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>KUĢA TIPS:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>
                                    {item?.ship?.type?.name ?? <em>Nav</em>}
                                  </span>
                                </div>
                              </div>
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>KUĢA E-PASTS:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>{item?.email ?? <em>Nav</em>}</span>
                                </div>
                              </div>
                            </div>
                            <div className="firstLevel">
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>IEPRIEKŠĒJĀ OSTA:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>{item?.prev_port?.name ?? <em>Nav</em>}</span>
                                </div>
                              </div>
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>NĀKOŠĀ OSTA:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>{item?.next_port?.name ?? <em>Nav</em>}</span>
                                </div>
                              </div>
                            </div>
                            <div className="firstLevel">
                              <div className="secondLevel">
                                <div className="thirdLevelLabel">
                                  <span>OSTA:</span>
                                </div>
                                <div className="thirdLevelValue">
                                  <span>
                                    {item?.port?.name ?? <em>Nav</em>}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                          :
                          <>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>VIZĪTES ID:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>{item.visitCode ?? <em>Nav</em>}</span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>TERMINĀLIS:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>{item.terminal?.name ?? <em>Nav</em>}</span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>IMO NUMURS:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>{item?.ship?.imo ?? <em>Nav</em>}</span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>DEKLARĒTĀJS:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>
                                  {item?.company?.name ?? <em>Nezināms</em>}
                                </span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>KUĢA TIPS:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>
                                  {item?.ship?.type?.name ?? <em>Nav</em>}
                                </span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>KUĢA E-PASTS:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>{item?.email ?? <em>Nav</em>}</span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>IEPRIEKŠĒJĀ OSTA:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>
                                  {item?.prev_port?.name ?? <em>Nav</em>}
                                </span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>NĀKOŠĀ OSTA:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>
                                  {item?.next_port?.name ?? <em>Nav</em>}
                                </span>
                              </div>
                            </div>
                            <div className="firstLevelMobile">
                              <div className="thirdLevelLabelMobile">
                                <span>OSTA:</span>
                              </div>
                              <div className="thirdLevelValue">
                                <span>
                                  {item?.port?.name ?? <em>Nav</em>}
                                </span>
                              </div>
                            </div>
                          </>
                        }
                        <Tabs defaultActiveKey="1" tabBarGutter="0" >
                          <Tabs.TabPane
                            key="1"
                            tab={
                              <span>
                                PAREDZĒTS NODOT ATKRITUMOS (
                              <Typography.Text strong>
                                  {isNaN(sum) ? 0 : sum.toFixed(2)} m<sup>3</sup>
                                </Typography.Text>
                              )
                            </span>
                            }
                          >
                            <SimpleTable
                              dataSource={item?.waste}
                              style={{ marginTop: 22 }}
                              columns={tables[`waste`].map((col) => ({ ...col }))}
                            />
                          </Tabs.TabPane>
                          <Tabs.TabPane
                            className="garbageTab"
                            tab="RADĪTO ATKRITUMU KVĪTIS"
                            key="2"
                            style={{ flexDirection: "row", flexWrap: "wrap" }}
                          >
                            <div className="declarationsLeftSide">
                              <div className="leftSideTop" style={{ width: "100%" }}>
                                {item.assignments.map((assignment) => {
                                  return assignment.declarations.map(
                                    (declaration, i) => {
                                      return (
                                        <div
                                          className="declarationLinkDiv"
                                          key={i}
                                        >
                                          <img src={paperclip} alt="" />
                                          <Link
                                            to={
                                              "/api/v1/declarations/" +
                                              declaration.id +
                                              "/previewPdf"
                                            }
                                            target="_blank"
                                          >
                                            {declaration.name}
                                          </Link>
                                        </div>
                                      );
                                    }
                                  );
                                })}
                              </div>
                              <div className="leftSideBottom">
                                {/* <Button
                                type="primary"
                                style={{ borderRadius: 5 }}
                              >
                                LABOT / PIEVIENOT JAUNU
                              </Button> */}
                              </div>
                            </div>
                            <div className="declarationsRightSide">
                              <div className="statusBox">
                                <div style={isMobile ? { display: "flex", flexDirection: "row", flexWrap: "wrap", marginBottom: "10px" } : {}}>
                                  <div className="declarationsBoxDescription">
                                    SKLOIS:
                                </div>
                                  <div className="declarationsBoxValue notSubmitted">
                                    <Typography.Text strong>
                                      -
                                  </Typography.Text>
                                  </div>
                                </div>
                                <div style={isMobile ? { display: "flex", flexDirection: "row", flexWrap: "wrap" } : {}}>
                                  <div className="declarationsBoxDescription">
                                    APUS:
                                </div>
                                  <div className="declarationsBoxValue notSubmitted">
                                    <Typography.Text strong>
                                      NAV IESNIEGTS
                                  </Typography.Text>
                                    <img src={greyCross} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tabs.TabPane>
                          <Tabs.TabPane
                            key="3"
                            tab={
                              <span>
                                <ArrowIcon style={{ marginRight: 12 }} />
                              NORĪKOT ŠOFERI
                            </span>
                            }
                          >
                            <div className="addDriverItem" style={isMobile ? { marginTop: "20px" } : {}}>
                              <div className={isMobile ? "" : "thirdTabRow"}>
                                <Form
                                  onFinish={this.assignDriver}
                                  ref={this.driverForm}
                                >
                                  <Row gutter="10" style={isMobile ? { display: "flex", flexDirection: "column", alignItems: "center" } : {}}>
                                    <div style={{ display: "flex", width: "100%", flexDirection: isMobile ? "column" : "row", minWidth: isMobile ? "50px" : "200px" }}>
                                      <div style={isMobile ? { display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" } : {}}>
                                        <Form.Item
                                          hidden
                                          name="shipVisitId"
                                          initialValue={item.id}
                                        />
                                        <Form.Item
                                          className={isMobile ? "mobileItem" : ""}
                                          name="driverId"
                                          label="ŠOFERIS"
                                          rules={[required]}
                                        >
                                          <Search
                                            mode="single"
                                            source="persons"
                                            placeholder="Norādiet šoferi"
                                            style={!isMobile ? { width: "auto", marginLeft: "5px" } : { width: "auto" }}
                                          />
                                        </Form.Item>
                                      </div>
                                      <div style={isMobile ? {} : { marginLeft: "30px" }}>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          style={{
                                            borderRadius: "5px",
                                            width: isMobile ? "80%" : "275px",
                                            marginLeft: isMobile ? "10%" : "",
                                            marginRight: "5px",
                                            marginBottom: isMobile ? "20px" : "",
                                            height: isMobile ? "auto" : "",
                                            whiteSpace: isMobile ? "normal" : "",
                                          }}
                                        >
                                          NORĪKOT ATKRITUMU SAVĀKŠANAI
                                    </Button>
                                      </div>
                                    </div>
                                  </Row>
                                </Form>
                              </div>
                            </div>
                            {item?.assignments.reverse().map((assignment) => (
                              <>
                                <div
                                  key={`asg-${assignment.id}`}
                                  className="driverItemDiv"
                                >
                                  <div>
                                    <Typography.Text className="inputValuesDriver">
                                      {assignment.created_at ? (
                                        <>
                                          {moment(assignment.created_at).format(
                                            `DD.MM.YYYY HH:mm`
                                          )}
                                        </>
                                      ) : (
                                          <em>Nav datuma</em>
                                        )}
                                    </Typography.Text>
                                  </div>
                                  <div className="driverInfoDiv shipView">
                                    <Typography.Text>Dispečeris </Typography.Text>
                                    <Typography.Text className="inputValuesDriver">
                                      {assignment.dispatcher?.name}{" "}
                                      {assignment.dispatcher?.surname}
                                    </Typography.Text>
                                    <Typography.Text> kuģa </Typography.Text>
                                    <Typography.Text className="inputValuesDriver">
                                      {item.ship?.name}
                                    </Typography.Text>
                                    <Typography.Text>
                                      {" "}
                                    radīto atkritumu savākšanai norīkoja šoferi{" "}
                                    </Typography.Text>
                                    <Typography.Text className="inputValuesDriver">
                                      {assignment.driver?.name}{" "}
                                      {assignment.driver?.surname}
                                    </Typography.Text>
                                    {assignment?.driver?.company?.name && (
                                      <Typography.Text className="inputValuesDriver">
                                        &nbsp;("
                                        {assignment?.driver?.company?.name}")
                                      </Typography.Text>
                                    )}
                                  .
                                </div>
                                </div>
                              </>
                            ))}
                          </Tabs.TabPane>
                        </Tabs>
                      </Layout.Content>
                    </Collapse.Panel>
                  </Collapse>
                );
              })}
            </div>
            <Pagination
              style={{ float: `right` }}
              size={state.pagination.size}
              total={state.pagination.total}
              current={state.pagination.page}
              onChange={(page, size) =>
                this.setState({
                  pagination: { ...state.pagination, page, size },
                  loading: true,
                })
              }
            />
          </Card>
        </Layout.Content>
      </>
    );
  };

  form = () => {
    return <></>;
  };
}

export default withRouter(ShipVisitsPage);
