import React from "react";
import BasicForm, { required } from "../lib/BasicForm";
import { withRouter } from "react-router-dom";
import Search from "../lib/widgets/Search";

import { Row, Col, Form, Input } from "antd";

class WasteTypePage extends BasicForm {
    tableLabel = `Atkritumu veidi`;
    newLabel = `Pievienot atkritumu veidu`;
    editLabel = `Labot atkritumu veidu`;

    location = "/classifiers/wasteType";
    source = `wasteType`;

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
                <Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item name="name" label="Nosaukums" rules={[required]}>
                        <Input
                            placeholder="Norādiet nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="nameEn"
                        label="Nosaukums angliski"
                    >
                        <Input
                            placeholder="Norādiet anglisko nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="description" label="Apraksts">
                        <Input
                            placeholder="Norādiet aprakstu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="code" label="Kods" rules={[required]}>
                        <Input
                            placeholder="Norādiet kodu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="orderNum" label="Kārtas numurs">
                        <Input
                            placeholder="Norādiet kārtas numuru"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="wasteGroupId"
                        label="Atkritumu grupa"
                        rules={[required]}
                    >
                        <Search
                            mode="single"
                            source="wasteGroup"
                            placeholder="Norādiet atkritumu grupu"
                            options={this.userData?.__options}
                        />
                    </Form.Item>
                    <Form.Item
                        name="apusClasses"
                        label="APUS izvēles opcijas"
                        tooltip="Apstiprinot kvīti un iesniedzot to APUS sistēmā, būs nepieciešams izvēlēties vienu no norādītajām opcijām."
                        rules={[required]}
                    >
                        <Search
                            mode="multiple"
                            source="apusWasteClasses"
                            placeholder="Norādiet APUS atkritumu klasses"
                            options={this.userData?.__apusOptions}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default withRouter(WasteTypePage);
