import React, { Component } from "react";
import { Layout, Button, Typography, Spin, notification, Card, Collapse } from "antd";
import { withRouter } from "react-router-dom";

import { acquire } from '../lib/api';

import "../App.css";
import "../css/Content.css";
import VisitStatus from "../lib/widgets/VisitStatus";
import SimpleTable from "../lib/widgets/SimpleTable";
import InfiniteScroll from 'react-infinite-scroll-component';

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

class AssignmentsPage extends Component {
  constructor(props) {
    super(props);
    this.source = `assignments`;

    this.state = {
      assignments: [],
      filter: `current`,
      loading: true,
	  pagination: {page:1, size:0, total:0},
	  needsRefresh: true,
    }
  }

  refresh = async (size=30) => {
	  this.setState({needsRefresh:false,loading:true})
    const response = await acquire(this.source, 1, size);
    if (response.ok && response.data.data!==0) {
      this.setState({
        assignments: response?.data?.data ?? [],
        loading: false,
		pagination:{...response?.data?.pagination},
      });
    } else {
      this.setState({ loading: false });
      notification.error({
        duration: 0,
        message: response?.data?.title ?? `Kļūda`,
        description: response?.data?.error ?? `Notikusi negaidīta kļūda, mēģiniet vēlreiz.`,
      });
    }
  };

  renderAssignments = (assignments) => {
    const { isMobile } = this.props;
    const { state } = this;
          state.assignments.forEach((item) => {
			assignments.push(
            <Collapse
              key={item.id}
              expandIconPosition="right"
              className="contentCollapse"
            >
              <Panel
                showArrow={false}
                className={isMobile?"assignmentsPanelMobile":"assignmentsPanel"}
                header={
                  <div style={isMobile?{display:"flex",flexDirection:"column", alignItems:"center"}:{}}>
                    <Text strong style={{ marginRight: 20, marginBottom:"5px" }}>
                      "{item.ship_visit?.ship?.name ?? <em>Nezināms</em>}"
                    </Text>
                    <Text type="secondary" strong style={{ marginRight: 20, marginBottom:"5px" }}>
                      {item.ship_visit?.terminal?.name ?? <em>Nav norādīts</em>}
                    </Text>
                    <VisitStatus {...item.ship_visit} />
                    <div className="collapsePanelExpand">
                      <div>+</div>
                    </div>
                  </div>
                }
              >
                <div className="assignmentsLeftSide">
                  <div className="assignmentCard">
                    <div>
                      <Text className="inputValuesDriver">
                        {item.dateCreated}
                      </Text>
                    </div>
                    <div className="driverInfoDiv shipView">
                      <Text>Dispečeris </Text>
                      <Text className="inputValuesDriver">
                        {item.dispatcher?.name} {item.dispatcher?.surname}
                      </Text>
                      <Text> kuģa </Text>
                      <Text className="inputValuesDriver">
                        "{item.ship_visit?.ship?.name ?? <em>Nezināms</em>}"
                    </Text>
                      <Text> radīto atkritumu savākšanai norīkoja šoferi </Text>
                      <Text className="inputValuesDriver">
                        {item.driver?.name} {item.driver?.surname} ("{item.driver.company?.name}").
                      </Text>
                    </div>
                  </div>
                  <div className="declarationsCard">
                    <Text className="assignmentDeclarationText">
                      {
                        item.declarations.length === 0 && (
                          <>Pagaidām netika pievienota neviena kvīts</>
                        )
                      }
                      {
                        item.declarations.length > 0 && (
                          <>Atrastas {item.declarations.length} kvītis</>
                        )
                      }
                    </Text>
                    <br />
                    <Button
                      type="primary"
                      style={{ borderRadius: 5 }}
                      className="addDeclarationToAssignment"
                      onClick={() => this.props.history.push(`/newdeclaration/${item.id}`)}
                    >
                      PIEVIENOT JAUNU
                    </Button>
                  </div>
                </div>
                <div className="assignmentsRightSide">
                  <SimpleTable
                    columns={[
                      {
                        title: ``,
                        key: `id`,
                        render: (txt, item) => item.type?.name ?? <em>Nav norādīts</em>,
                      },
                      {
                        title: `NODOŠANAI`,
                        key: `forDisposal`,
                        align: `center`,
                        sorter: {
                          compare: (a, b) => a.forDisposal - b.forDisposal,
						  multiple: 1,
                        },
						render: (txt)=> <>{Number(txt)} m<sup>3</sup></>
                      }
                    ]}
                    dataSource={item.ship_visit?.waste ?? []}
                  />
                </div>
              </Panel>
            </Collapse>
			)
		  })
  }

  render() {
    const { state } = this;
	const { isMobile } = this.props;
	if(state.needsRefresh){
		this.refresh();
	}
	let assignments=[];
	this.renderAssignments(assignments);
    return (
		<>
			<Spin
				size="large"
				style={{
					display: this.state.loading ? "" : "none",
					position: `absolute`,
					zIndex: 9999,
					top: `50%`,
					left: `50%`,
				}}
			/>
			<Content style={{ padding:isMobile?"0px 10px": "9px 35px", minHeight: 280, opacity: this.state.loading ? "0.5" : "1", }}>
				<Card
				bordered={false}
				title={
					<Text type="secondary" style={{ fontSize: 15 }}>
					Saņemtie norīkojumi
					</Text>
				}
				//   extra={
				//     !isMobile &&
				//     (
				//       <>
				//         <Button
				//           style={{ marginRight: 19, borderRadius: 5 }}
				//           type={(state.filter === `current`) ? `primary` : `default`}
				//           onClick={() => this.setState(old => ({ filter: `current`, loading: old.filter !== `current` }))}
				//         >
				//           TEKOŠIE NORĪKOJUMI
				//         </Button>
				//         <Button
				//           style={{ marginRight: 19, borderRadius: 5 }}
				//           type={(state.filter === `all`) ? `primary` : `default`}
				//           onClick={() => this.setState(old => ({ filter: `all`, loading: old.filter !== `all` }))}
				//         >
				//           RĀDĪT VISUS
				//         </Button>
				//       </>
				//     )
				//   }
				bodyStyle={{ minHeight: 300, position: `relative` }}
				>
				{/* {
					!!isMobile && (
					<>
						<div style={{ float: `right`, marginTop: -5 }}>
						<Button
							style={{ marginRight: 19, borderRadius: 5 }}
							type={(state.filter === `current`) ? `primary` : `default`}
							onClick={() => this.setState(old => ({ filter: `current`, loading: old.filter !== `current` }))}
						>
							TEKOŠIE NORĪKOJUMI
						</Button>
						<Button
							style={{ marginRight: 19, borderRadius: 5 }}
							type={(state.filter === `all`) ? `primary` : `default`}
							onClick={() => this.setState(old => ({ filter: `all`, loading: old.filter !== `all` }))}
						>
							RĀDĪT VISUS
						</Button>
						</div>
						<br />
					</>
					)
				} */}
				<InfiniteScroll
					style={{ overflow: "hidden" }}
					dataLength={state.pagination.size}
					hasMore={state.pagination.size<state.pagination.total && !state.loading}
					next={()=>{
						this.setState({loading:true});
						this.refresh(state.pagination.size+5);
					}}
					scrollThreshold={0.9}
					loader={
						<Spin
						size="large"
						style={{
							position: `relative`,
							zIndex: 9999,
							top: `0`,
							left: `50%`,
						}}
						/>
					}
				>
					<div>{assignments}</div>
				</InfiniteScroll>
				</Card>
			</Content >
	  	</>
    );
  }
}

export default withRouter(AssignmentsPage);
