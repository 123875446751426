import { Component } from 'react';
import { Typography } from 'antd';
import moment from 'moment';

class VisitDate extends Component {
    render() {
        const { props } = this;
        let from = null;
        let to = null;

        if (props.ata) {
            from = moment(props.ata).format(`DD.MM.YYYY`);
        } else if (props.eta) {
            from = moment(props.eta).format(`DD.MM.YYYY`);
        }

        if (props.atd) {
            to = moment(props.atd).format(`DD.MM.YYYY`);
        } else if (props.etd) {
            to = moment(props.etd).format(`DD.MM.YYYY`);
        }

        let dateTimeString = ``;
        if (from && to) {
            dateTimeString = `${from} - ${to}`;
        } else if (from) {
            dateTimeString = from;
        } else if (to) {
            dateTimeString = to;
        }

        return (
            <Typography.Text type="secondary" strong style={{ marginRight: "20px" }}>
                {dateTimeString}
            </Typography.Text>
        );
    }
}

export default VisitDate;