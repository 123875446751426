import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert, Button } from 'antd';

class NoAccessPage extends Component {
    render() {
        return (
            <div style={{ padding: 20 }}>
                <Alert
                    message={this.props.title ?? `Pieeja liegta`}
                    description={this.props.message ?? `Jums trūkst piekļuves tiesību šai lapai`}
                    type="error"
                    showIcon
                />
								{this.props.resetButton && <Button style={{marginTop:"15px"}} type="primary" onClick={this.props.passwordResetFunction}>{this.props.resetMessage??"Nosūtīt epastu"}</Button>}
            </div>
        );
    }
}

export default withRouter(NoAccessPage);
