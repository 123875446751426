import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, message, Spin, Table } from "antd";
import { post, acquire } from "../lib/api";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentlyEditingId: -1,
    };
  }

  getConfigs = async () => {
    this.setState({ loading: true });
    await new Promise((r) => setTimeout(r, 200));
    const response = await acquire(`config`, null, null, []);
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      this.setState({ configs: { ...response.data }.data });
    } else if (response.data && response.data.error) {
      message.error(response.data.error);
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.getConfigs().then(() => {
      this.setState({ loading: false });
    });
  }

  submit = async (data) => {
    const response = await post(`config`, data);
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data.message);
    } else if (response.data && response.data.error) {
      message.error(response.data.error);
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }

    this.setState({ allowSubmit: true });
  };

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            position: `relative`,
            width: "100%",
            height: "100%",
            background: `#f0f2f5`,
          }}
        >
          <Spin
            size="large"
            style={{
              position: `absolute`,
              top: `50%`,
              left: `50%`,
              transform: `translateY(-50%)`,
            }}
          />
        </div>
      );
    }
    const columns = [
      {
        title: "Iestatījuma apraksts",
        dataIndex: "description",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Vērtība",
        dataIndex: "value",
        key: "action",
        sorter: (a, b) => a.value - b.value,
        render: (text, record) =>
          record.id === this.state.currentlyEditingId ? (
            <Form.Item
              preserve={false}
              style={{ position: "absolute", top: "25%" }}
              name={record.id}
              rules={[
                {
                  required: true,
                  pattern: new RegExp("^\\d+$"),
                  message: "Vesels skaitlis",
                },
              ]}
              initialValue={record.value}
            >
              <Input style={{ width: "50px" }} />
            </Form.Item>
          ) : (
            text
          ),
      },
      {
        render: (text, record) =>
          record.id === this.state.currentlyEditingId ? (
            <>
              <Button type="primary" htmlType="submit" style={{marginRight:"10px", marginBottom:"10px"}}>
                Saglabāt
              </Button>
              <Button
                onClick={() => {
                  this.setState({ currentlyEditingId: -1 });
                }}
              >
                Atcelt
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={() => this.setState({ currentlyEditingId: record.id })}
            >
              Rediģēt
            </Button>
          ),
      },
    ];
    return (
      <Form
        style={{ width: "100%", height: "100%" }}
        onFinish={(data) => {
          this.setState({ currentlyEditingId: -1 });
          this.submit(data).then(this.getConfigs());
        }}
      >
        <Table
          columns={columns}
          dataSource={this.state.configs}
          pagination={false}
        />
      </Form>
    );
  }
}

export default withRouter(SettingsPage);
