import React from 'react';
import BasicForm, { required } from '../lib/BasicForm';
import { Form, Input, Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import Search from '../lib/widgets/Search';

const eaqulPassword = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || getFieldValue(`password`) === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`Parolēm jāsakrīt`));
    },
});

const phoneValidator = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value) return Promise.resolve();
        if (value.match(/^([+]{1}[0-9]+( )?)?([0-9]+)$/) === null) {
            return Promise.reject(new Error(`Ievadiet derīgu telefona numuru`));
        } else {
            return Promise.resolve();
        }
    },
});


const emailValidator = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value) return Promise.resolve();
        if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){1,}$/) === null) {
            return Promise.reject(new Error(`Ievadiet derīgu e-pastu`));
        } else {
            return Promise.resolve();
        }
    },
});

const isValidPassword = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value) {
            return Promise.resolve();
        } else if (value.length < 9) {
            return Promise.reject(new Error(`Parolei jābūt vismaz 9 rakstzīmes garai.`));
        } else if (value.match(/[A-Z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens lielais latīņu alfabēta burts.`));
        } else if (value.match(/[a-z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens mazais latīņu alfabēta burts.`));
        } else if (value.match(/[^A-Za-z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens cipars vai speciāls simbols.`));
        }
        return Promise.resolve();
    },
});

class UsersPage extends BasicForm {
    tableLabel = `Lietotāju saraksts`;
    newLabel = `Pievienot lietotāju`;
    editLabel = `Labot lietotāju`;
    
    location = `/users`;
    source = `users`;

    onChange = () => {
        this.forceUpdate();
    };

    form() {
        const { state } = this;
		const {isMobile} = this.props;

        let password1Rules = state.case === `edit` ? [isValidPassword] : [required, isValidPassword];
        let password2Rules = state.case === `edit` ? [eaqulPassword] : [required, eaqulPassword];
        if (this.formRef.current) {
            if (this.formRef.current.getFieldValue(`password`)?.length > 0) {
                password2Rules = [required, eaqulPassword];
            }
        }

        return (
            <>
                <Row>
					<Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                        <Form.Item name="username" label="Lietotājvārds" rules={[required]}>
                            <Input placeholder="Norādiet lietotājvārdu" autoComplete={false} />
                        </Form.Item>
                        <Form.Item name="name" label="Vārds" rules={[required]}>
                            <Input placeholder="Norādiet vārdu" autoComplete={false} />
                        </Form.Item>
                        <Form.Item name="surname" label="Uzvārds" rules={[required]}>
                            <Input placeholder="Norādiet uzvārdu" autoComplete={false} />
                        </Form.Item>
                        <Form.Item name="email" label="E-pasts" rules={[emailValidator]}>
                            <Input placeholder="Norādiet e-pastu" autoComplete={false} />
                        </Form.Item>
                        <Form.Item name="phone" label="Tel. numurs" rules={[phoneValidator]}>
                            <Input placeholder="Norādiet telefona numuru" autoComplete={false} />
                        </Form.Item>
                        <Form.Item name="roles" label="Lomas" rules={[required]}>
                            <Search
                                mode="multiple"
                                source="roles"
                                placeholder="Norādiet lomu(as)"
                                options={this.userData?.__options}
                            />
                        </Form.Item>
                        <Form.Item name="companyId" label="Uzņēmums">
                            <Search
                                source="companies"
                                placeholder="Norādiet uzņēmumu"
                                options={this.userData?.__companies}
                            />
                        </Form.Item>
                        <Form.Item name="password" label="Parole" rules={password1Rules}>
                            <Input.Password placeholder="Norādiet paroli" autoComplete={false} />
                        </Form.Item>
                        <Form.Item name="password2" label="Parole atkārtoti" rules={password2Rules} hidden={password2Rules.length === 1}>
                            <Input.Password placeholder="Atkārtoti ievadiet paroli" autoComplete={false} />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(UsersPage);
