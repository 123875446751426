import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
    Typography,
    notification,
    message,
    Layout,
    Card,
    Button,
    Collapse,
    Spin,
} from "antd";
import moment from "moment";

import { post, acquire } from "../lib/api";

import paperclip from "../images/paperclip.png";
// import greenCheckmark from "../images/greenCheckmark.svg";
import checkmarkDeclaration from "../images/checkmarkDeclaration.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import greyCross from "../images/greyCross.svg";

const { Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

export class InvoiceStatus extends Component {
    render() {
        const { status } = this.props;

        switch (status) {
            case `PENDING`:
                return (
                    <div className="shipVisitComplete">
                        <Typography.Text>NEIESNIEGTS</Typography.Text>
                    </div>
                );

            case `FINISHED`:
                return (
                    <div className="comingIn">
                        <Typography.Text>IESNIEGTA</Typography.Text>
                    </div>
                );

            default:
                return (
                    <div className="awaitingApproval">
                        <Typography.Text>KĻŪDA</Typography.Text>
                    </div>
                );
        }
    }
}

class InvoicesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            declarations: [],
            filter: `all`,
            loading: true,
            pagination: { page: 1, size: 0, total: 0 },
			needsRefresh: true,
        };
    }

    acceptDeclaration = async (id) => {
        const response = await post(`declarations/accept`, { id });
        if (response.ok) {
            message.success(response?.data?.message ?? `Kvīts iesniegta.`);
        } else {
            notification.error({
                duration: 0,
                message: response?.data?.title ?? `Kļūda`,
                description:
                    response?.data?.error ??
                    `Notikusi negaidīta kļūda, mēģiniet vēlreiz.`,
            });
        }
    };

    refresh = async (size = 30) => {
		this.setState({needsRefresh:false, loading:true})
        const response = await acquire(`declarations`, 1, size);
        if (response.ok && response.data.data !== 0) {
            this.setState({
                declarations: response?.data?.data?.slice() ?? [],
                loading: false,
                pagination: { ...response?.data?.pagination },
            });
        } else {
            notification.error({
                duration: 0,
                message: response?.data?.title ?? `Kļūda`,
                description:
                    response?.data?.error ??
                    `Notikusi negaidīta kļūda, mēģiniet vēlreiz.`,
            });

            this.setState({ loading: false });
        }
    };

    renderInvoices(invoices) {
        const { state } = this;
        state.declarations.forEach((item) => {
            invoices.push(
                <Collapse
                    expandIconPosition="right"
                    className="contentCollapse"
                    key={item.id}
                >
                    <Panel
                        header={
                            <>
                                <Text strong style={{ marginRight: 20 }}>
                                    {`IMO${
                                        item?.assignment?.ship_visit?.ship
                                            ?.imo ?? `00000000`
                                    }`}
                                </Text>
                                <Text
                                    type="secondary"
                                    strong
                                    style={{ marginRight: 20 }}
                                >
                                    {item?.assignment?.ship_visit?.ship
                                        ?.name ?? <em>Nezināms</em>}
                                </Text>
                                <InvoiceStatus
                                    status={item?.status ?? `ERROR`}
                                />
                                <div className="collapsePanelExpand">
                                    <div>+</div>
                                </div>
                            </>
                        }
                        showArrow={false}
                    >
                        <div>
                            <Text className="inputValuesDriver">
                                {moment(item.created_at).format(
                                    `DD.MM.YYYY HH:mm`
                                )}
                            </Text>
                            <br />
                            <Text>Šoferis </Text>
                            <Text strong>
                                {`${item.assignment?.driver?.name} ${item.assignment?.driver?.surname} `}
                                ("{item.assignment?.driver.company?.name}").
                            </Text>
                            <div className="driverInfoDiv declarationsPage">
                                <div className="declarationLinkDiv declarationsPage">
                                    <img src={paperclip} alt="" />
                                    <Link
                                        to={`/api/v1/declarations/${item.id}/previewPdf`}
                                        target="_blank"
                                    >
                                        {item?.name ?? `Kvīts_0000000000.pdf`}
                                    </Link>
                                </div>
                            </div>
                            <Button
                                type="primary"
                                className="acceptDeclarationButton"
                                onClick={() => this.acceptDeclaration(item.id)}
                            >
                                <img
                                    src={checkmarkDeclaration}
                                    className="checkmarkAccept"
                                    alt=""
                                />
                                APSTIPRINĀT
                            </Button>
                            <Link to={`/editdeclaration/${item.id}`}>
                                <Button
                                    danger
                                    type="primary"
                                    className="addDeclarationButton"
                                >
                                    LABOT
                                </Button>
                            </Link>
                        </div>
                        <div className="declarationStatus">
                            <div className="statusBox">
                                <div>
                                    <div className="declarationsBoxDescription">
                                        SKLOIS:
                                    </div>
                                    <div className="declarationsBoxValue notSubmitted">
                                        <Typography.Text strong>
                                            NAV IESNIEGTS
                                        </Typography.Text>
                                        <img src={greyCross} alt="" />
                                    </div>
                                </div>
                                <div>
                                    <div className="declarationsBoxDescription">
                                        APUS:
                                    </div>
                                    <div className="declarationsBoxValue notSubmitted">
                                        <Typography.Text strong>
                                            NAV IESNIEGTS
                                        </Typography.Text>
                                        <img src={greyCross} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {item.reasons?.reverse().map((reason) => (
                            <div className="driverItemDiv">
                                <div>
                                    <Text className="inputValuesDriver">
                                        {moment(item.created_at).format(
                                            `DD.MM.YYYY HH:mm`
                                        )}
                                        {`${reason.user.name} ${reason.user.surname}`}
                                    </Text>
                                </div>
                                <div className="driverInfoDiv shipView">
                                    <Text>Iemesls labojumam: </Text>
                                    <Text className="inputValuesDriver">
                                        {reason.reason}
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </Panel>
                </Collapse>
            );
        });
    }

    render() {
        const { state } = this;
        const { isMobile } = this.props;
		if(state.needsRefresh){
			this.refresh();
		}
        let invoices = [];
        this.renderInvoices(invoices);

        return (
            <>
                <Spin
                    size="large"
                    style={{
                        display: this.state.loading ? "" : "none",
                        position: `absolute`,
                        zIndex: 9999,
                        top: `50%`,
                        left: `50%`,
                    }}
                />
                <Content
                    style={{
                        padding:isMobile?"0px 10px": "9px 35px",
                        minHeight: 280,
                        opacity: this.state.loading ? "0.5" : "1",
                    }}
                >
                    <Card
                        bordered={false}
                        title={
                            <Text type="secondary" style={{ fontSize: 15 }}>
                                Kuģu radīto atkritumu savākšanas kvītis
                            </Text>
                        }
                        bodyStyle={{ minHeight: 300, position: `relative` }}
                        extra={
                            !isMobile && (
                                <>
                                    <Button
                                        style={{
                                            marginRight: 19,
                                            borderRadius: 5,
                                        }}
                                        type={
                                            state.filter === `all`
                                                ? `primary`
                                                : `default`
                                        }
                                        onClick={() =>
                                            this.setState((old) => ({
                                                filter: `all`,
                                                needsRefresh: old.filter !== `all`,
                                            }))
                                        }
                                    >
                                        VISAS
                                    </Button>
                                    <Button
                                        style={{
                                            marginRight: 19,
                                            borderRadius: 5,
                                        }}
                                        type={
                                            state.filter === `inactive`
                                                ? `primary`
                                                : `default`
                                        }
                                        onClick={() =>
                                            this.setState((old) => ({
                                                filter: `inactive`,
                                                needsRefresh: old.filter !== `inactive`,
                                            }))
                                        }
                                    >
                                        NEIESNIEGTĀS
                                    </Button>
                                </>
                            )
                        }
                    >
                        {!!isMobile && (
                            <>
                                <div style={{ float: `right`, marginTop: -5 }}>
                                    <Button
                                        style={{
                                            marginRight: 19,
                                            borderRadius: 5,
                                        }}
                                        type={
                                            state.filter === `all`
                                                ? `primary`
                                                : `default`
                                        }
                                        onClick={() =>
                                            this.setState((old) => ({
                                                filter: `all`,
                                                needsRefresh: old.filter !== `all`,
                                            }))
                                        }
                                    >
                                        VISAS
                                    </Button>
                                    <Button
                                        style={{
                                            marginRight: 19,
                                            borderRadius: 5,
                                        }}
                                        type={
                                            state.filter === `inactive`
                                                ? `primary`
                                                : `default`
                                        }
                                        onClick={() =>
                                            this.setState((old) => ({
                                                filter: `inactive`,
                                                needsRefresh: old.filter !== `inactive`,
                                            }))
                                        }
                                    >
                                        NEIESNIEGTĀS
                                    </Button>
                                </div>
                                <br />
                            </>
                        )}
                        <InfiniteScroll
                            style={{ overflow: "hidden" }}
                            dataLength={state.pagination.size}
                            hasMore={
                                state.pagination.size <
                                    state.pagination.total && !state.loading
                            }
                            next={() => {
                                this.setState({ loading: true });
                                this.refresh(state.pagination.size + 5);
                            }}
                            scrollThreshold={0.9}
                            loader={
                                <Spin
                                    size="large"
                                    style={{
                                        position: `relative`,
                                        zIndex: 9999,
                                        top: `0`,
                                        left: `50%`,
                                    }}
                                />
                            }
                        >
                            <div>{invoices}</div>
                        </InfiniteScroll>
                    </Card>
                </Content>
            </>
        );
    }
}

export default withRouter(InvoicesPage);
