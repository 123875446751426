import React from 'react';
import BasicForm, { required } from '../lib/BasicForm';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Input } from 'antd';
import Search from '../lib/widgets/Search';

class RolesPage extends BasicForm {
    tableLabel = `Lomu saraksts`;
    newLabel = `Pievienot lomu`;
    editLabel = `Labot lomu`;

    location = "/roles";
    source = `roles`;

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
				<Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item name="name" label="Nosaukums" rules={[required]}>
                        <Input placeholder="Norādiet nosaukumu" autoComplete={false} />
                    </Form.Item>
                    <Form.Item name="permissions" label="Tiesības" rules={[required]}>
                        <Search
                            mode="multiple"
                            source="permissions"
                            placeholder="Norādiet tiesības"
                            options={this.userData?.__options}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default withRouter(RolesPage);
