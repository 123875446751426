import React from 'react';
import { withRouter } from 'react-router-dom';
import BasicForm, { required } from '../lib/BasicForm';
import { Form, Input, InputNumber, Row, Col } from 'antd';

class RiskGroupsPage extends BasicForm {
    tableLabel = `Riska grupu saraksts`;
    newLabel = `Pievienot riska grupu`;
    editLabel = `Pielāgot riska grupu`;

    source = `riskGroups`;
    location = `/configuration/risk-groups`;

    config_1 = null;
    config_2 = null;

    allowNew = () => {
        return false;
    };

    updateDescription = (val, key = 1) => {
        this[`config_${key}`] = val;

        let description = this.userData.description.replace(`{1}`, this.config_1).replace(`{2}`, this.config_2);
        this.formRef.current.setFieldsValue({ description });

        return val;
    };

    config = {
        R1: (
            <Form.Item
                label="Dienas līdz ETD"
                name="config_1"
                rules={[required]}
                getValueFromEvent={(val) => this.updateDescription(val)}

            >
                <InputNumber placeholder="Norādiet dienas" min={0} />
            </Form.Item>
        ),
        R2: (
            <Form.Item
                label="Dienas līdz ETD"
                name="config_1"
                rules={[required]}
                getValueFromEvent={(val) => this.updateDescription(val)}
            >
                <InputNumber placeholder="Norādiet dienas" min={0} />
            </Form.Item>
        ),
        R3: <></>,
        R4: (
            <Form.Item
                label="Stundas līdz ETD"
                name="config_1"
                rules={[required]}
                getValueFromEvent={(val) => this.updateDescription(val)}
            >
                <InputNumber placeholder="Norādiet stundas" min={0} />
            </Form.Item>
        ),
        R5: (
            <Form.Item
                name="config_1"
                label="Procenti"
                rules={[required]}
                getValueFromEvent={(val) => this.updateDescription(val)}
            >
                <InputNumber placeholder="Norādiet procentus" min={0} max={100} formatter={(val) => `${val}%`} />
            </Form.Item>
        ),
        R6: <></>,
        R7: <></>,
        R8: (
            <>
                <Form.Item
                    name="config_1"
                    label="Procenti"
                    rules={[required]}
                    getValueFromEvent={(val) => this.updateDescription(val)}
                >
                    <InputNumber placeholder="Norādiet procentus" min={0} max={100} formatter={(val) => `${val}%`} />
                </Form.Item>
                <Form.Item
                    name="config_2"
                    label="Stundas līdz ETD"
                    rules={[required]}
                    getValueFromEvent={(val) => this.updateDescription(val, 2)}
                >
                    <InputNumber placeholder="Norādiet stundas" min={0} />
                </Form.Item>
            </>
        ),
    };

    allowDelete = () => {
        return false;
    };

    formatData = (data) => {
        const ret = { ...data };

        this.config_1 = ret.config_1;
        this.config_2 = ret.config_2;

        ret.description = ret.description.replace(`{1}`, ret.config_1).replace(`{2}`, ret.config_2);

        return ret;
    };

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
                <Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item label="Nosaukums" name="name">
                        <Input placeholder="Ievadiet nosaukumu" disabled />
                    </Form.Item>
                    <Form.Item label="Apraksts" name="description">
                        <Input.TextArea placeholder="Ievadiet nosaukumu" disabled />
                    </Form.Item>
                    {this.config[this.userData.name]}
                </Col>
            </Row>
        );
    };
}

export default withRouter(RiskGroupsPage);
