import Cookies from 'js-cookie';

export const acquire = (source, page = 1, size = 10, extra = [], headers = {}, version = `v1`) => {
    let host = ``;
    if (window.location.hostname === `localhost`) {
        host = `http://localhost:5000`;
    }

    if (size) extra.unshift({ key: `size`, value: size });
    if (page) extra.unshift({ key: `page`, value: page });

    return new Promise((r) => {
        fetch(
            `${host}/api/${version}/${source}${extra.length ? `?` : ``}${extra
                .map((p) => `${p.key}=${p.value}`)
                .join(`&`)}`,
            {
                headers: {
                    Accept: `application/json`,
                    "Content-Type": `application/json`,
                    Authorization: Cookies.get(`Authorization`),
                    ...headers,
                },
            }
        )
            .then(async (data) => {
                const response = { ok: data.ok, status: data.status };
                const _data = await data.json();
                r({ ...response, data: _data });
            })
            .catch((err) =>{
                if(err.name==="SyntaxError"){
                    // console.log("Unexpected api call return");
                }
                else{
                    // console.log("api error",err.name);
                    console.error(err)
                }
            } );
    });
};

export const post = async (route, item, id = undefined, version = `v1`) => {
    let host = ``;
    if (window.location.hostname === `localhost`) {
        host = `http://localhost:5000`;
    }

    if (item) {
        for (const i in item) {
            if (item[i] === undefined) {
                item[i] = null;
            }
        }
    }

    return new Promise((r, f) => {
        fetch(`${host}/api/${version}/${route}${id && id !== `new` ? `/${id}` : ``}`, {
            method: id ? `PATCH` : `POST`,
            headers: {
                Accept: `application/json`,
                "Content-Type": `application/json`,
                Authorization: Cookies.get(`Authorization`),
            },
            body: JSON.stringify(item ?? {}),
        })
            .then(async (data) => {
                const response = { ok: data.ok, status: data.status };
                const _data = await data.json();
                r({ ...response, data: _data });
            })
            .then((data) => r(data))
            .catch((err) => f(err));
    });
};

export const destroy = async (route, id, version = `v1`) => {
    let host = ``;
    if (window.location.hostname === `localhost`) {
        host = `http://localhost:5000`;
    }
    return new Promise((r, f) => {
        fetch(`${host}/api/${version}/${route}/${id}`, {
            method: `DELETE`,
            headers: {
                Accept: `application/json`,
                "Content-Type": `application/json`,
                Authorization: Cookies.get(`Authorization`),
            },
        })
            .then(async (data) => {
                const response = { ok: data.ok, status: data.status };
                const _data = await data.json();
                r({ ...response, data: _data });
            })
            .then((data) => r(data))
            .catch((err) => f(err));
    });
};

export const calcTextWidth = (text, font) => { // +- 10px
    let context = document.createElement(`canvas`).getContext(`2d`);
    context.font = font;
    return context.measureText(text).width;
  }