import React from "react";
import { withRouter } from "react-router-dom";
import CustomForm from "../lib/CustomForm";
import { Pagination, Layout, Typography, Button, Collapse, Card } from "antd";

import VisitStatus from "../lib/widgets/VisitStatus";
import { calcTextWidth } from "../lib/api";

class RisksPage extends CustomForm {
  location = `/risks`;
  source = `risks`;
  hash = null;

  pannels = [];

  getExtraProperties = () => {
    const eProps = [{ key: `showAll`, value: this.state.showAll ?? true }];

    if (this.props.port) {
      eProps.push({ key: `port`, value: this.props.port });
    }

    return eProps;
  };

  componentDidUpdate() {
    if (this.hash !== window.location.hash) {
      this.hash = window.location.hash;

      this.refs[this.hash]?.current?.scrollIntoView({ behavior: `smooth` });
    }
  }

  updatePannels = (active, id) => {
    this.pannels[id] = active;
    this.forceUpdate();
  };

  renderItems = (data) => {
    const { state } = this;
	const {isMobile} = this.props;

    let w1 = 0,
      w2 = 0;

    for (const i of data) {
      if (!this.refs[`#risk-${i.id}`]) {
        this.refs[`#risk-${i.id}`] = React.createRef();
      }

      if (i.risks.length) {
        let shipName = `"${i?.ship.name.toUpperCase()}"`;
        let terminalName = i?.terminal?.name.toUpperCase() ?? `NAV TERMINĀĻA`;

        let t1 = calcTextWidth(shipName, `bold 14px -apple-system`);
        let t2 = calcTextWidth(terminalName, `bold 14px -apple-system`);

        w1 = w1 > t1 ? w1 : t1;
        w2 = w2 > t2 ? w2 : t2;
      }
    }

    const pannels = [];
    for (const i in data) {
      if (this.hash !== window.location.hash) {
        pannels[i] = window.location.hash === `#risk-${data[i].id}`;
      } else {
        pannels[i] = this.pannels[i] ?? false;
      }
      this.pannels[i] = pannels[i];
    }

    return (
      <>
        <Layout.Content style={{ padding:isMobile?"0px 10px": "9px 35px", minHeight: 280 }}>
          <Card
            title={
              <div className="contentHeader" style={isMobile?{flexWrap:"wrap"}:{}}>
                <div className="contentHeaderName">
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "11pt", marginBottom:"10px"}}
                  >
                    Iespējamie riski
                  </Typography.Text>
                </div>
                <div className="contentHeaderButtons" style={isMobile?{display:"flex", flexDirection:"row",flexWrap:"wrap"}:{}}>
                  <Button
                    style={{ borderRadius: "5px", marginBottom:isMobile?"10px":"", marginRight:"20px"}}
                    type={state.showAll ?? true ? `default` : `primary`}
                    onClick={() =>
                      this.setState({
                        showAll: false,
                        loading: state.showAll ?? true,
                      })
                    }
                  >
                    TEKOŠIE RISKI
                  </Button>
                  <Button
                    float="right"
                    style={{ borderRadius: "5px", marginBottom:isMobile?"10px":"" }}
                    type={state.showAll ?? true ? `primary` : `default`}
                    onClick={() =>
                      this.setState({
                        showAll: true,
                        loading: !(state.showAll ?? true),
                      })
                    }
                  >
                    RĀDĪT VISUS
                  </Button>
                </div>
              </div>
            }
          >
            {data.map(
              (item, i) =>
                !!item.risks.length && (
                  <>
                    <div ref={this.refs[`#risk-${item.id}`]} />
                    <Collapse
                      expandIconPosition="right"
                      className="contentCollapse"
                      onChange={(a) => this.updatePannels(!!a.length, i)}
                      activeKey={pannels[i] ? `panel-${i}` : undefined}
                    >
                      <Collapse.Panel
                        key={`panel-${i}`}
                        showArrow={false}
                        header={
                          <>
                            <Typography.Text
                              strong
                              style={{
                                marginRight: "20px",
                                textTransform: `uppercase`,
                                display: `inline-block`,
                                width: w1,
                              }}
                            >
                              "{item?.ship.name}"
                            </Typography.Text>
                            <Typography.Text
                              strong
                              type="secondary"
                              style={{
                                marginRight: "20px",
                                textTransform: `uppercase`,
                                display: `inline-block`,
                                width: w2,
                              }}
                            >
                              {item?.terminal?.name ?? <em>NAV TERMINĀĻA</em>}
                            </Typography.Text>
                            <VisitStatus {...item} />
                            <div className="collapsePanelExpand">
                              <div>+</div>
                            </div>
                          </>
                        }
                      >
                        {item.risks.map((risk, j) => (
                          <div className="riskDiv">
                            <div className="riskiFirstLine">
                              <Typography.Text
                                type="danger"
                                strong
                                className="boldDangerRisk"
                              >
                                {risk.name}
                              </Typography.Text>
                              <Typography.Text type="danger">
                                {" "}
                                RISKA IESTĀŠANĀS VARBŪTĪBA
                              </Typography.Text>
                            </div>
                            <div className="riskInfoDiv">
                              <Typography.Text>
                                {risk.description}
                              </Typography.Text>
                            </div>
                          </div>
                        ))}
                      </Collapse.Panel>
                    </Collapse>
                  </>
                )
            )}
            <Pagination
              style={{ float: `right` }}
              size={state.pagination.size}
              total={state.pagination.total}
              current={state.pagination.page}
              onChange={(page, size) =>
                this.setState({
                  pagination: { ...state.pagination, page, size },
                  loading: true,
                })
              }
            />
          </Card>
        </Layout.Content>
      </>
    );
  };

  form = () => {
    return <></>;
  };
}

export default withRouter(RisksPage);
