import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Alert } from 'antd';

class OfflinePage extends Component {
    render() {
        return (
            <div style={{ padding: 20 }}>
                <Alert
                    message={this.props.title ?? `Saturs nav pieejams`}
                    description={this.props.message ?? `Saturs pašlaik nav pieejams. Vai Jūsu ierīcei ir piekļuve internetam?`}
                    type="error"
                    showIcon
                />
            </div>
        );
    }
}

export default withRouter(OfflinePage);
