import React from "react";
import BasicForm, { required } from "../lib/BasicForm";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, Input } from "antd";

class ShipTypePage extends BasicForm {
    tableLabel = `Kuģa tipu saraksts`;
    newLabel = `Pievienot kuģa tipu`;
    editLabel = `Labot kuģa tipu`;

    location = "/classifiers/shipType";
    source = `shipType`;

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
                <Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item name="name" label="Nosaukums" rules={[required]}>
                        <Input
                            placeholder="Norādiet nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="internalCode"
                        label="Iekšējais kods"
                        rules={[]}
                    >
                        <Input
                            placeholder="Norādiet iekšējo kodu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="skloisCode" label="SKLOIS kods" rules={[]}>
                        <Input
                            placeholder="Norādiet SKLOIS kodu"
                            autoComplete={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default withRouter(ShipTypePage);
