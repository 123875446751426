import React, { Component } from "react";
import "./App.css";
import "./css/Navbar.css";
import {
  Layout,
  Typography,
  Dropdown,
  Menu,
  Input,
  Button,
  Empty,
  message,
  Row,
  Col,
} from "antd";
// import { BrowserRouter as Router, Redirect, withRouter } from "react-router-dom";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { MenuUnfoldOutlined } from "@ant-design/icons";

import kaupsLogo from "./images/kaupsLogo.svg";
import bellIcon from "./images/bellIcon.svg";
import search from "./images/search.svg";
import trashBin from "./images/trashBin.svg";
import logout from "./images/logout.png";
import { user, can } from "./lib/User";
import Search from "./lib/widgets/Search";

import { destroy } from './lib/api';

const { Header } = Layout;
const { Text } = Typography;
// const { Search } = Input;

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.searchImoRef = React.createRef();

    this.state = {
      port: undefined,
      // searchBarVal: undefined,

      // izvada konsolē pa vecam
      searchBarVal: ``,
    }
  }

  clear = async () => {
    let success = true;
    for (const not of user.notifications) {
      const response = await destroy(`notifications`, not.id);
      success &= (response.ok && response.status === 200);
    }

    if (success) {
      message.success(`Paziņojumi notīrīti.`)
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }
    await user.sync();
  };

  hide = async (e, id) => {
    e.stopPropagation();
    const response = await destroy(`notifications`, id);
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data.message);
    } else if (response.data && response.data.error) {
      message.error(response.data.error);
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }
    await user.sync();
  };

  open = (url) => {
    if (url) {
      this.props.history.push(url);
      this.props.parent.forceUpdate();
    }
  };

  logout = async () => {
    await user.logout();
    this.props.parent.forceUpdate();
  };

  searchButton = (evt) => {
    this.setState({ searchBarVal: evt.target.value });
    this.open("/ships");
  };

  render() {
    const { state } = this;
    const { isMobile, parent } = this.props;



    return (
      <Router>
        <Header className="header">
          <Row gutter={10} style={{ width: `100%`, color: `white` }}>
            <Col span={20} lg={8} style={{ display: `flex` }}>
              <div className="vcenter">
                <img src={kaupsLogo} alt="" style={{ width: 50, height: 50 }} />
              </div>
              <Text
                strong
                style={{
                  top: 15,
                  left: 60,
                  fontSize: 22,
                  color: `white`,
                  display: `block`,
                  lineHeight: `22px`,
                  position: `absolute`,
                  fontFamily: `Arial`,
                }}
              >
                KAUPS
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: `white`,
                  display: `block`,
                  lineHeight: `14px`,
                  fontFamily: `Arial`,
                  padding: `40px 0 10px 5px`,
                  textTransform: `uppercase`,
                }}
              >
                {user.roles.length ? user.roles.join(`, `) : `Lietotājs`}
              </Text>
            </Col>
            {(can('Skatīt savas kuģa vizītes') || can('Skatīt visas kuģa vizītes' || can('Labot kuģa vizītes'))) &&
              <Col span={0} lg={6}>
                <div className="vcenter" style={{ width: `100%` }}>
                  <div>
                    <Text
                      style={{
                        color: `white`,
                        marginRight: 5,
                      }}
                    >
                      OSTA:
                </Text>
                    <Search
                      // name="portId"
                      source="ports"
                      placeholder="Norādiet ostu"
                      value={parent.state.port}
                      style={{ maxWidth: 250, width: `calc(100% - 50px)` }}
                      onChange={port => { parent.setState({ port }); console.log(port); if (port) { this.open("/ships") } }}
                    />
                  </div>
                </div>
              </Col>}
            <Col span={4} lg={10} style={{ position: "absolute", right:isMobile? "2.2%":"40px", top: "16px" }}>
              {
                !isMobile && (
                  <div style={{ float: `right`, display: `flex`, height: `100%` }}>
                    {(can('Skatīt savas kuģa vizītes') || can('Skatīt visas kuģa vizītes' || can('Labot kuģa vizītes'))) &&
                      <>
                        <div className="vcenter">
                          <Input
                            bordered={false}

                            ref={this.searchImoRef}
                            style={{ color: `white` }}
                            value={parent.state.searchBarVal}
                            placeholder="IMO vai kvīts numurs..."
                            onPressEnter={(e) => { parent.setState({ searchBarVal: e.target.value }); if (e) { this.open("/ships") } }}
                            onChange={(e) => parent.setState({ searchBarVal: e.target.value })}

                          // onChange={searchImoClick} // - izvērtēt nepieciešamību
                          />
                        </div>
                        <div className="vcenter">
                          <Button type="text" onClick={this.searchButton}>
                            <img src={search} alt="" />
                          </Button>
                        </div>
                      </>
                    }
                    <div className="vcenter" >
                      <Dropdown
                        trigger={['click']}
                        visible={state.visible ?? false}
                        onVisibleChange={(visible) => this.setState({ visible })}
                        overlay={
                          !!user.notifications?.length ? (
                            <Menu>
                              {
                                user.notifications.map(not => (
                                  <Menu.Item onClick={() => this.open(not.url)}>
                                    <div className="menuItemWidth">
                                      <div className="notificationMenuItemLimiter">
                                        <div className="notificationsTrashAlign">
                                          <Text>{not.title}</Text>
                                          <img src={trashBin} alt="" onClick={(e) => this.hide(e, not.id)} />
                                        </div>
                                        <Text
                                          type="secondary"
                                          className="notificationDescription"
                                        >
                                          {not.description}
                                        </Text>
                                      </div>
                                    </div>
                                  </Menu.Item>
                                ))
                              }
                              <Menu.Item className="clearNotificationsItem" onClick={() => this.clear()}>
                                <div className="menuItemWidth">
                                  <Text type="secondary">NOTĪRĪT PAZIŅOJUMUS</Text>
                                </div>
                              </Menu.Item>
                            </Menu>
                          ) : (
                              <div
                                style={{ padding: `5px 30px`, minWidth: 180 }}
                                className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical"
                              >
                                <Empty
                                  description="Nav notifikāciju"
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                              </div>
                            )
                        }
                        className="notificationsDropdown"
                        placement="bottomRight"
                        arrow
                      >
                        <div>
                          <img src={bellIcon} alt="" />
                          {
                            !!user.notifications?.length && (
                              <div className="notificationCount">
                                <p>{user.notifications?.length}</p>
                              </div>
                            )
                          }
                        </div>
                      </Dropdown>
                    </div>
                    <div className="vcenter">
                      <Dropdown
                        className="profileAvatarOverlay"
                        overlay={
                          <Menu className="avatarMenu">
                            <Menu.Item>
                              <div className="logoutDropdownWidth" onClick={() => this.props.history.push(`/profile`)}>
                                <div class="logoutIconDiv"></div>
                                <div>
                                  <Text>Iestatījumi</Text>
                                </div>
                              </div>
                            </Menu.Item>
                            <Menu.Item>
                              <div className="logoutDropdownWidth">
                                <div class="logoutIconDiv">
                                  <img src={logout} alt="" />
                                </div>
                                <div>
                                  <Text onClick={this.logout}>Atslēgties</Text>
                                </div>
                              </div>
                            </Menu.Item>
                          </Menu>
                        }
                        placement="bottomCenter"
                        arrow
                      >
                        <div
                          className="profileAvatarNavbar"
                          style={{
                            color: `black`,
                            cursor: `pointer`,
                            userSelect: `none`,
                            textTransform: `uppercase`,
                            margin: `0 10px`,
                          }}
                        >
                          {user.name.substring(0, 1)}
                        </div>
                      </Dropdown>
                    </div>
                    <div className="vcenter" style={{ alignSelf: "center" }}>
                      <div className="usernameNavbar">
                        <Text style={{ color: "rgba(255, 255, 255, 0.847058823529412)" }}>
                          {`${user.name} ${user.surname}`}
                        </Text>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                isMobile && (
                  <div className="vcenter" style={{ float: `right` }}>
                    <Button
                      type="text"
                      style={{ textTransform: `uppercase` }}
                      onClick={() => {
                        parent.setState((old) => ({ collapsed: !old.collapsed }));
                        document.body.style.overflowY = isMobile ? `hidden` : `auto`;
                      }}
                    >
                      <MenuUnfoldOutlined style={{ color: `white`, fontSize: `2em` }} />
                    </Button>
                  </div>
                )
              }
            </Col>
          </Row>
        </Header>
      </Router >
    );
  }
}

export default withRouter(NavBar);
