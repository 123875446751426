import React from "react";
import { Card, Typography, Menu, Layout } from "antd";
import CustomForm from "../lib/CustomForm";
import { withRouter } from "react-router-dom";

const classifiers = [
    {
        key: "shipType",
        name: "KUĢU TIPI",
    },
    {
        key: "wasteGroup",
        name: "ATKRITUMU GRUPAS",
    },
    {
        key: "wasteType",
        name: "ATKRITUMU VEIDI",
    },
    {
        key: "port",
        name: "OSTAS",
    },
    {
        key: "country",
        name: "VALSTIS",
    },
];

class ClassifiersPage extends CustomForm {
    render() {
        return (
            <Layout.Content>
                <Card
                    bordered={false}
                    title={
                        <Typography.Text
                            type="secondary"
                            style={{ fontSize: 15 }}
                        >
                            Klasifikatoru saraksts
                        </Typography.Text>
                    }
                >
                    <Menu>
                        {classifiers.map((classifier) => {
                            return (
                                <Menu.Item
                                    className="classifierItem"
                                    onClick={() => {
                                        this.props.history.push(
                                            "/classifiers/" + classifier.key
                                        );
                                    }}
                                >
                                    <Typography.Text
                                        strong
                                        style={{ marginRight: "20px" }}
                                    >
                                        {classifier.name}
                                    </Typography.Text>
                                </Menu.Item>
                            );
                        })}
                    </Menu>
                </Card>
            </Layout.Content>
        );
    }
}

export default withRouter(ClassifiersPage);
