import React, { Component } from 'react';
import { Layout, Row, Col, Image, Typography, Form, Input, Button, Spin, notification, message } from 'antd';
import { withRouter } from 'react-router-dom';

import kaupsLogo from "../images/kaupsLogo.svg";
import { post } from '../lib/api';
import { required } from '../lib/BasicForm';

import '../css/Login.css';

const eaqulPassword = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value || getFieldValue(`password`) === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(`Parolēm jāsakrīt`));
    },
});

const isValidPassword = ({ getFieldValue }) => ({
    validator(_, value) {
        if (!value) {
            return Promise.resolve();
        } else if (value.length < 9) {
            return Promise.reject(new Error(`Parolei jābūt vismaz 9 rakstzīmes garai.`));
        } else if (value.match(/[A-Z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens lielais latīņu alfabēta burts.`));
        } else if (value.match(/[a-z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens mazais latīņu alfabēta burts.`));
        } else if (value.match(/[^A-Za-z]/) === null) {
            return Promise.reject(new Error(`Parolei jāsatur vismaz viens cipars vai speciāls simbols.`));
        }
        return Promise.resolve();
    },
});

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            waiting: false,
            valid: false,
        };
    }

    validateKey = async (key) => {
        console.log(key);
        const response = await post(`checkPasswordKey`, { key });
        if (response.ok && response.status === 200) {
            if (response.data.valid) {
                this.setState({ loading: false, waiting: false, valid: true });
            } else {
                this.setState({ loading: false, waiting: false, valid: false });
                notification.error({
                    duration: 0,
                    message: `Nederīgs links`,
                    description: response.data?.reason,
                    onClose: () => window.location.reload(),
                });
            }
        } else if (response.data && response.data.error) {
            this.setState({ loading: false, waiting: false, valid: false });
            notification.error({
                duration: 0,
                message: response.data?.title,
                description: response.data?.error,
                onClose: () => window.location.reload(),
            });
        } else {
            this.setState({ loading: false, waiting: false, valid: false });
            notification.error({
                duration: 0,
                message: `500 Servera kļūda`,
                description: `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
                onClose: () => window.location.reload(),
            });
        }
    };

    submit = async (data) => {
        const response = await post(`resetPassword`, { ...data, key: this.props.token });
        if (response.ok && response.status === 200) {
            message.success(response.data?.message);
            this.props.history.push(`/`);
        } else if (response.data && response.data.error) {
            this.setState({ loading: false, waiting: false, valid: false });
            notification.error({
                duration: 0,
                message: response.data?.title,
                description: response.data?.error,
            });
        } else {
            this.setState({ loading: false, waiting: false, valid: false });
            notification.error({
                duration: 0,
                message: `500 Servera kļūda`,
                description: `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
            });
        }
    };

    render() {
        const { state, props } = this;

        if (state.loading) {
            this.validateKey(props.token);
            return (
                <div style={{ position: `absolute`, top: 0, left: 0, bottom: 0, right: 0, background: `#f0f2f5` }}>
                    <Spin size="large" style={{ position: `absolute`, top: `50%`, left: `50%`, transform: `translateY(-50%)` }} />
                </div>
            );
        }

        return (<Layout style={{ minHeight: "100vh" }}>
            <Layout.Content style={{ padding: "0 50px", margin: "23px 0px 0px 0px" }}>
                <Row justify="center">
                    <Col
                        span="8"
                        className="login-box"
                    >
                        <Row>
                            <Col
                                xs={0}
                                sm={0}
                                md={10}
                                className="login-image"
                            >
                                <Image
                                    width="100%"
                                    height="100%"
                                    preview={false}
                                    src={kaupsLogo}
                                    style={{
                                        transform: `translateY(-50%)`,
                                        position: `absolute`,
                                        top: `50%`,
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={14} style={{ minHeight: `30vh`, padding: 30 }}>
                                <Typography.Title level={5} style={{ textAlign: `center` }}>ATIESTATĪT PAROLI</Typography.Title>
                                <br />
                                <Form onFinish={this.submit}>
                                    <Form.Item name="password" rules={[required, isValidPassword]}>
                                        <Input.Password placeholder="Parole" disabled={!state.valid} />
                                    </Form.Item>
                                    <Form.Item name="password2" rules={[required, eaqulPassword]}>
                                        <Input.Password placeholder="Parole vēlreiz" disabled={!state.valid} />
                                    </Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={state.waiting}
                                        disabled={state.waiting || !state.valid}
                                        style={{ width: `100%` }}
                                    >
                                        Saglabāt
                                </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
        );
    }
}

export default withRouter(ResetPasswordPage);
