import React, { Component } from 'react';
import { Table, Empty, Button, Form, message, Modal, Divider, Checkbox, Spin, Card, Typography, Row, Col } from 'antd';
import { matchPath, Redirect } from 'react-router-dom';
import {
    ReloadOutlined,
    MoreOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import tables from './tables';
import { acquire, post, destroy } from './api';

import "../App.css";
import { user } from './User';
import NoAccessPage from '../pages/NoAccess';

const { Text } = Typography;

export const required = {
    required: true,
    message: `Šis lauks jāaizpilda obligāti!`,
};

class DataTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: tables[props.source].map((col) => ({ ...col })),
            page: 1,
            size: 10,
            sorters: [],
            loading: true,
            data: [],
            config: false,
            showAll: false,
        };
    }

    reset = () => {
        const { props, state } = this;

        this.load(state.page, state.size, [])
        this.setState({
            columns: tables[props.source].map((col) => ({ ...col })),
            sorters: [],
            config: false,
        });
    };

    componentDidMount() {
        const { state } = this;
        this.load(state.page, state.size, state.sorters);
    }

    load = async (page, size, sorters, all = false) => {
        const { props } = this;
        this.setState({ loading: true });

        const extra = [];

        if (sorters.length) {
            extra.push({
                key: `sort`,
                value: JSON.stringify(sorters),
            });
        }

        let pagination = this.state.pagination ?? { page: 1, size: 10, total: 10 };
        const response = all ? (await acquire(props.source + "-all", page, size, extra)) : (await acquire(props.source, page, size, extra));
        if (response.ok && response.status === 200 && response.data.data !== 0) {
            pagination = { ...pagination, ...response.data?.pagination };
            this.setState({ data: response.data?.data, ...pagination });
        } else if (response.data && response.data.error) {
            message.error(response.data.error);
        } else {
            message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
        }

        this.setState({ loading: false });
    };
    handleCheck = (e) => {
        if (e.target.checked) {
            this.load(this.state.page, this.state.size, this.state.sorters, true);
            this.setState({ showAll: true });
        }
        else {
            this.load(this.state.page, this.state.size, this.state.sorters);
            this.setState({ showAll: false });
        }
    }

    render() {
        const { state, props } = this;

        const columns = [];

        let i = 0;
        let hasChecked = false;
        let hasUnchecked = false;

        for (const col of state.columns) {
            col.dataIndex = col.key;
            if (col.hidden) {
                continue;
            }

            hasChecked |= col.show ?? true;
            hasUnchecked |= !(col.show ?? true);

            if (col.show === false) {
                continue;
            }

            if (col.sorter) {
                col.sorter = { multiple: i++ };

                const s = state.sorters.filter(s => s.key === col.key);
                col.sortOrder = s.length ? (s[0].dir === `desc` ? `descend` : `ascend`) : false;
            }

            columns.push(col);
        }

        return (
            <div style={{ width: `100%`, padding: 10 }}>
                {
                    !!props.allowNew && (
                        <Button type="primary" onClick={() => props.callback(null, `new`)}><PlusOutlined /> JAUNS</Button>
                    )
                }
                <div style={{ float: `right`, marginBottom:"15px"}}>
                    {
                        props.showAllButton && (
                            <Checkbox onChange={this.handleCheck}>{props.showAllText || "Rādīt visus"}</Checkbox>
                        )
                    }
                    <Button onClick={() => this.state.showAll ? this.load(state.page, state.size, state.sorters, true) : this.load(state.page, state.size, state.sorters)}><ReloadOutlined /></Button>
                    <Button style={{ marginLeft: 5 }} onClick={() => this.setState({ config: true })}><MoreOutlined /></Button>
                </div>
                <Table
					scroll={{x:true}}
                    columns={columns}
                    dataSource={state.data}
                    loading={state.loading}
                    showSorterTooltip={false}
                    style={{ width: `100%`, marginTop: 15 }}
                    onChange={(p, f, s) => {
                        const sorters = [];
                        if (s) {
                            if (!Array.isArray(s)) {
                                s = [s];
                            }

                            for (const i of s) {
                                if (i.columnKey && i.order) {
                                    sorters.push({
                                        key: i.columnKey,
                                        dir: i.order === `descend` ? `desc` : `asc`,
                                    });
                                }
                            }
                        }
                        this.setState({ sorters, page: p.current, size: p.pageSize });
                        if (!this.state.showAll) {
                            this.load(p.current, p.pageSize, sorters);
                        }
                        else {
                            this.load(p.current, p.pageSize, sorters, true);
                        }
                    }}
                    locale={{
                        emptyText: (
                            <Empty
                                description="Nav datu. Vai Jūsu ierīcei ir piekļuve internetam?"
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                style={{ margin: `80px auto` }}
                            />
                        ),
                    }}
                    onRow={(row) => ({
                        onClick: () => {
                            props.callback(row.id, `edit`);
                        },
                    })}
                    pagination={{
                        total: state.total,
                        showSizeChanger: true,
                    }}
                />
                <Modal
                    centered
                    visible={state.config}
                    title="Tabulas kolonnu iestatījumi"
                    onOk={() => this.setState({ config: false })}
                    onCancel={() => this.setState({ config: false })}
                    footer={[
                        <Button onClick={this.reset}>Atcelt</Button>,
                        <Button
                            type="primary"
                            onClick={() => this.setState({ config: false })}
                        >
                            Saglabāt
                        </Button>,
                    ]}
                >
                    <Checkbox
                        indeterminate={hasChecked && hasUnchecked}
                        checked={hasChecked}
                        onChange={(e) => {
                            for (const col of state.columns) {
                                col.show = e.target.checked;
                            }
                            this.forceUpdate();
                        }}
                    >
                        Atzīmēt visas
                    </Checkbox>
                    <Divider />
                    {state.columns.map((col, i) => {
                        if (!col.hidden) {
                            return (
                                <div key={i}>
                                    <Checkbox
                                        checked={col.show ?? true}
                                        key={`cc-column-${i}`}
                                        onChange={(e) => {
                                            col.show = e.target.checked;
                                            this.forceUpdate();
                                        }}
                                    >
                                        {col.title}
                                    </Checkbox>
                                    <br />
                                </div>
                            );
                        }
                        return <></>;
                    })}
                </Modal>
            </div>
        );
    }
}

class BasicForm extends Component {
    constructor(props) {
        super(props);

        this.newLabel = `Izveidot ierakstu`;
        this.editLabel = `Labot ierakstu`;
        this.tableLabel = ``;

        this.formRef = React.createRef();
        this.userData = {};

        this.state = {
            id: null,
            case: null,
            allowSubmit: true,
            loading: false,
            isError: false,
            errorTitle: undefined,
            errorMessage: undefined,
        };
    }

    showAllButton = () => {
        return false;
    }

    allowNew = () => {
        return true;
    };

    formatData = (data) => {
        return { ...data };
    };

    fetchData = async (id) => {
        this.userData = {};

        let error = { isError: false, errorTitle: undefined, errorMessage: undefined };

        if (id && this.state.loading) {
            const response = await acquire(`${this.source}/${id}`, null, null);
            if (response.ok && response.status === 200 && response.data.data !== 0) {
                this.userData = { ...response.data };
            } else if (response.data && response.data.error) {
                message.error(response.data.error);
                error = { isError: true, errorTitle: response.data?.title, errorMessage: response.data.error };
            } else {
                error = { isError: true, errorTitle: `Sistēmas kļūda`, errorMessage: `Kaut kas nogāja greizi, mēģiniet vēlreiz.` };
                message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
            }
        }

        this.setState({ loading: false, ...error });
    };

    static getDerivedStateFromProps(props, state) {
        let newFormMatch = matchPath(props.location.pathname, { path: `${props.match.path}/new`, strict: true, exact: true });
        let editFormMatch = matchPath(props.location.pathname, { path: `${props.match.path}/:id`, strict: true, exact: true });

        if (newFormMatch) {
            return {
                id: null,
                case: `new`,
                loading: false,
                allowSubmit: state.case === `new` ? state.allowSubmit : true,
            };
        } else if (editFormMatch && !isNaN(editFormMatch.params.id)) {
            return {
                id: editFormMatch.params.id,
                case: `edit`,
                loading: state.case !== `edit` || state.id !== editFormMatch.params.id || state.loading,
                allowSubmit: state.case === `edit` ? state.allowSubmit : true
            };
        } else {
            return {
                id: null,
                case: `table`,
                loading: false,
                allowSubmit: state.case === `table` ? state.allowSubmit : true,
            };
        }
    }

    allowDelete = () => {
        return true;
    }

    goToDefaultPage = () => {
        this.setPage(null, `table`);
    };

    setPage = (id, _case) => {
        switch (_case) {
            case `new`:
                this.props.history.push(`${this.location}/new`);
                break;

            case `edit`:
                this.props.history.push(`${this.location}/${id}`);
                break;

            default:
            case `table`:
                this.props.history.push(`${this.location}`);
                break;
        }

        this.setState({ id, case: _case });
        user.sync();
    };

    submit = async (data) => {
        const { state } = this;
        this.setState({ allowSubmit: false });

        const response = await post(this.source, data, state.id);
        if (response.ok && response.status === 200 && response.data.data !== 0) {
            message.success(response.data.message);
            this.setPage(null, `table`);
        } else if (response.data && response.data.error) {
            message.error(response.data.error);
            this.setState({ allowSubmit: true });
        } else {
            message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
            this.setState({ allowSubmit: true });
        }
        user.sync();
    };

    onChange = () => {
    };

    delete = async () => {
        const { state } = this;
        const response = await destroy(this.source, state.id);
        if (response.ok && response.status === 200 && response.data.data !== 0) {
            message.success(response.data.message);
            this.setPage(null, `table`);
        } else if (response.data && response.data.error) {
            message.error(response.data.error);
            this.setState({ allowSubmit: true });
        } else {
            message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
            this.setState({ allowSubmit: true });
        }
        user.sync();
    };

    render() {
        const { state } = this;
		const {isMobile} = this.props;
        if (state.loading) {
            this.fetchData(this.state.id);
            return <Spin size="large" style={{ position: `fixed`, zIndex: 9999, top: `50%`, left: `50%`, transform: `transalte(-50%)` }} />;
        }

        if (state.isError) {
            return <NoAccessPage title={state.errorTitle} message={state.errorMessage} />;
        }

        switch (state.case) {
            case `new`:
                return (
                    <div style={{ padding: 15, width: `100%`, minHeight: 250, paddingBottom: 50, position: `relative` }}>
                        <Card
                            bordered={false}
                            title={
                                <Text type="secondary" style={{ fontSize: 15 }}>
                                    {this.newLabel}
                                </Text>
                            }
                            style={{ width: `100%` }}
                            bodyStyle={{ minHeight: 300, position: `relative` }}
                        >
                            <Redirect to={`${this.location}/new`} />
                            <Form
                                id="basic-form"
                                layout="vertical"
                                ref={this.formRef}
                                onFinish={this.submit}
                                onValuesChange={this.onChange}
                            >
                                {this.form()}
                            </Form>
                            <Row style={{ borderTop: `1px solid #f0f0f0` }}>
                                <Col span={isMobile?"":24} style={{ paddingTop: 10, width:isMobile?"100%":"" }}>
                                    <div style={{ float: `right` }}>
                                        {
                                            this.allowDelete() &&
                                            <Button type="primary" danger onClick={this.delete}>Dzēst</Button>
                                        }
                                        <Button style={{ marginLeft: 10 }} onClick={() => this.setPage(null, `table`)}>Atcelt</Button>
                                        <Button
                                            type="primary"
                                            form="basic-form"
                                            htmlType="submit"
                                            style={{ marginLeft: 10 }}
                                            disabled={!state.allowSubmit}
                                            loading={!state.allowSubmit}
                                        >
                                            Saglabāt
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                );
            case `edit`:
                return (
                    <div style={{ padding: 15, width: `100%`, minHeight: 250, paddingBottom: 50, position: `relative` }}>
                        <Card
                            bordered={false}
                            title={
                                <Text type="secondary" style={{ fontSize: 15 }}>
                                    {this.editLabel}
                                </Text>
                            }
                            style={{ width: `100%` }}
                            bodyStyle={{ minHeight: 300, position: `relative` }}
                        >
                            <Redirect to={`${this.location}/${state.id}`} />
                            <Form
                                id="basic-form"
                                layout="vertical"
                                ref={this.formRef}
                                onFinish={this.submit}
                                initialValues={this.formatData(this.userData)}
                                onValuesChange={this.onChange}
                            >
                                {this.form()}
                            </Form>
                            <Row style={{ borderTop: `1px solid #f0f0f0` }}>
                                <Col span={24} style={{ paddingTop: 10 }}>
                                    <div style={{ float: `right` }}>
                                        {
                                            this.allowDelete() &&
                                            <Button type="primary" danger onClick={this.delete}>Dzēst</Button>
                                        }
                                        <Button style={{ marginLeft: 10 }} onClick={() => this.setPage(null, `table`)}>Atcelt</Button>
                                        <Button
                                            type="primary"
                                            form="basic-form"
                                            htmlType="submit"
                                            style={{ marginLeft: 10 }}
                                            disabled={!state.allowSubmit}
                                            loading={!state.allowSubmit}
                                        >
                                            Saglabāt
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                );

            case `table`:
                return (
                    <>
                        <Card
                            bordered={false}
                            title={
                                <Text type="secondary" style={{ fontSize: 15 }}>
                                    {this.tableLabel}
                                </Text>
                            }
                            style={{ width: `100%` }}
                            bodyStyle={{ minHeight: 300, position: `relative` }}
                        >
                            <Redirect to={`${this.location}`} />
                            <DataTable
                                allowNew={this.allowNew()}
                                source={this.source}
                                location={this.location}
                                callback={this.setPage}
                                showAllButton={this.showAllButton()}
                                showAllText={this.showAllText}
                            />
                        </Card>
                    </>
                );

            default:
                return <></>;
        }
    }
}

export default BasicForm;
