import { Component } from 'react';
import { Typography } from 'antd';
import moment from 'moment';

export class VisitStatus extends Component {
    render() {
        const { props } = this;

        let status = `NEZINĀMS`;
        let className = `awaitingApproval`;

        if (props.ata) {
            if (props.atd) {
                status = `VIZĪTE PABEIGTA`;
                className = `shipVisitComplete`;
            } else if (props.etd) {
                const diff = moment(props.etd).diff(moment(), `days`);
                if (diff < 1) {
                    status = `GATAVOJAS PAMEST ŠODIEN`;
                    className = `awaitingApproval`;
                } else if (diff === 1) {
                    status = `GATAVOJAS PAMEST RĪTDIEN`;
                    className = `awaitingApproval`;
                } else {
                    status = `ATRODAS OSTĀ`;
                    className = `inPort`;
                }
            } else {
                status = `ATRODAS OSTĀ`;
                className = `inPort`;
            }
        } else if (props.eta) {
            status = `GATAVOJAS IENĀKT`;
            className = `comingIn`;
        }

        return (
            <div className={className} style={{...this.props?.style}}>
                <Typography.Text>{status}</Typography.Text>
            </div>
        );
    }
}

export default VisitStatus;