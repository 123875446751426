import React, { Component } from "react";
import "../App.css";
import "../css/Content.css";
import "../css/Profile.css";
import {
  Input,
  Layout,
  Button,
  Alert,
  Select,
  Typography,
  Collapse,
  Form,
  Col,
  message,
  Row,
  notification,
} from "antd";
import { withRouter } from "react-router-dom";
import checkmarkDeclaration from "../images/checkmarkDeclaration.svg";
import { user } from "../lib/User";
import { required } from "../lib/BasicForm";
import { post } from "../lib/api";

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const phoneValidator = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value) return Promise.resolve();
    if (value.match(/^([+]{1}[0-9]+( )?)?([0-9]+)$/) === null) {
      return Promise.reject(new Error(`Ievadiet derīgu telefona numuru`));
    } else {
      return Promise.resolve();
    }
  },
});

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowSubmit: true,
    };
  }

  submit = async (data) => {
    this.setState({ allowSubmit: false });

    const response = await post(`profile`, data);
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data.message);
    } else if (response.data && response.data.error) {
      message.error(response.data.error);
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }

    this.setState({ allowSubmit: true });
    user.sync();
  };

  sendPasswordEMail = async () => {
    const response = await post(`sendResetPasswordEMail`, { email: user.email });
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data?.message);
    } else if (response.data?.error) {
      notification.error({
        duration: 0,
        message: response.data?.title,
        description: response.data?.error,
      });
    } else {
      notification.error({
        duration: 0,
        message: `500 Servera kļūda`,
        description: `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
      });
    }
  };

  render() {
    const { state } = this;
    const { isMobile } = this.props;

    return (
      <Content style={{ padding: isMobile ? "0px 10px" : "9px 35px", minHeight: 280 }}>
        <div className="contentHeader">
          <div class="contentHeaderName">
            <Text type="secondary" style={{ fontSize: "11pt" }}>
              Lietotāja profils
            </Text>
          </div>
        </div>
        <Collapse
          defaultActiveKey="1"
          expandIconPosition="right"
          collapsible="disabled"
          className="contentCollapse"
        >
          <Panel
            header={
              <>
                {
                  user?.company?.name && (
                    <Text strong style={{ marginRight: 20, textTransform: `uppercase` }}>
                      {user?.company?.name}
                    </Text>
                  )
                }
                <Text type="secondary" strong style={{ marginRight: 20, textTransform: `uppercase` }}>
                  {user?.roles?.join(`, `) ?? `Lietotājs`}
                </Text>
              </>
            }
            showArrow={false}
            key="1"
          >
            <Form onFinish={this.submit} initialValues={user}>
              <Row>
                <Col span={isMobile ? "" : "8"} style={isMobile ? { width: "100%" } : {}}>
                  <Form.Item name="name" label="Vārds" rules={[required]} labelAlign={isMobile ? "left" : ""} style={isMobile ? { display: "grid", justifyContent: "stretch" } : {}}>
                    <Input placeholder="Norādiet vārdu" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={isMobile ? "" : "8"} style={isMobile ? { width: "100%" } : {}}>
                  <Form.Item name="surname" label="Uzvārds" rules={[required]} labelAlign={isMobile ? "left" : ""} style={isMobile ? { display: "grid", justifyContent: "stretch" } : {}}>
                    <Input placeholder="Norādiet uzvārdu" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={isMobile ? "" : "8"} style={isMobile ? { width: "100%" } : {}}>
                  <Form.Item name="phone" label="Tālrunis" rules={[phoneValidator]} labelAlign={isMobile ? "left" : ""} style={isMobile ? { display: "grid", justifyContent: "stretch" } : {}}>
                    <Input placeholder="Norādiet tālruni" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={isMobile ? "" : "8"} style={isMobile ? { width: "100%" } : {}}>
                  <Form.Item
                    disabled
                    name="email"
                    label="E-pasts"
                    labelAlign={isMobile ? "left" : ""} style={isMobile ? { display: "grid", justifyContent: "stretch" } : {}}
                  >
                    <Input disabled placeholder="Norādiet e-pastu" />
                  </Form.Item>
                </Col>
                <Col style={{ paddingLeft: isMobile ? "" : 10, marginTop: -5, marginBottom: isMobile ? "10px" : "" }}>
                  <Alert
                    message="Lai nomainītu e-pasta adresi, lūdzu sazinieties ar administratoru"
                    showIcon={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={isMobile ? "" : "8"} style={isMobile ? { width: "100%" } : {}}>
                  <Form.Item name="language" label="Valoda" labelAlign={isMobile ? "left" : ""} style={isMobile ? { display: "grid", justifyContent: "stretch" } : {}}>
                    <Select className="languageSelect">
                      <Option value="lv">Latviešu (LV)</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <hr className="profileLine" />
              <div className="profileButtonRow" style={isMobile ? { flexWrap: "wrap" } : {}}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="saveProfileButton"
                  disabled={!state.allowSubmit}
                  loading={!state.allowSubmit}
                >
                  {
                    state.allowSubmit && (
                      <img
                        src={checkmarkDeclaration}
                        className="checkmarkAccept"
                        alt=""
                      />
                    )
                  }
                SAGLABĀT
              </Button>
                <Button type="primary" className="changePasswordButton" onClick={this.sendPasswordEMail}>
                  MAINĪT PAROLI
              </Button>
                <Alert
                  message="Paroles atjaunošanas informācija tiks nosūtīta uz Jūsu e-pastu!"
                  showIcon={true}
                  style={isMobile ? { marginTop: "10px", height: "auto" } : { height: "auto" }}
                />
              </div>
            </Form>
          </Panel>
        </Collapse>
      </Content>
    );
  }
}

export default withRouter(ProfilePage);
