import React from "react";
import BasicForm, { required } from "../lib/BasicForm";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, Input } from "antd";

class PortPage extends BasicForm {
    tableLabel = `Ostu saraksts`;
    newLabel = `Reģistrēt ostu`;
    editLabel = `Labot ostu`;

    location = "/classifiers/port";
    source = `port`;
    showAllText = "Rādīt arī ārvalstu ostas";

    showAllButton = () => {
        return true;
    }

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
                <Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item name="name" label="Nosaukums" rules={[required]}>
                        <Input
                            placeholder="Norādiet nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="code" label="Kods" rules={[]}>
                        <Input
                            placeholder="Norādiet kodu"
                            autoComplete={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default withRouter(PortPage);
