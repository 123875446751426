import { message, notification } from "antd";
import { post, acquire } from "./api";

class User {
  constructor() {
    this.id = 0;
    this.name = `Anonīms`;
    this.surname = `Lietotājs`;
    this.username = `-`;
    this.mustResetPassword = false;
    this.email = ``;
    this.phone = ``;

    this.roles = [];
    this.permissions = [];
    this.company = {};

    this.hasAuthorized = false;
  }
  async getResources(origin = null) {
    origin !== null && console.log("precaching api respones: " + origin);
    try {
      acquire('assignments', 1, 30);
      acquire('declarations', 1, 30);
      acquire('risks', 1, 10, [{ key: "showAll", value: true }]);
      acquire('visits', 1, 10, [{ key: "showAll", value: true }]);
      // also requires precise sorters for each page, that would normally be called by default
    }
    catch (error) {
      console.error("problem getting resources");
    }

  }
  login = async (username, password, remember) => {
    return new Promise(async (r) => {
      if (this.hasAuthorized) {
        message.error(`Lietotājs jau ir autorizējies`);
        r();
      }

      const data = { username, password, remember };
      const response = await post(`login`, data, null, `auth`);
      if (response.ok && response.data && response.data.access_token) {
        this.hasAuthorized = true;
        this.mustResetPassword = response.data.mustResetPassword ?? false;
        await this.sync();
        await this.getResources();
        notification.destroy();
        message.success(`Pieslēgšanās veiksmīga.`);
      } else if (response?.data?.error) {
        this.hasAuthorized = false;
        notification.destroy();
        notification.error({
          duration: 0,
          message: response.data.title,
          description: response.data.error,
        });
      } else {
        this.hasAuthorized = false;
        notification.destroy();
        notification.error({
          duration: 0,
          message: `500 Servera kļūda`,
          description: `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
        });
      }
      r();
    });
  };

  sync = async (that) => {
    if (that) {
      this.app = that;
    }

    return new Promise(async (r) => {
      const response = await post(`me`, null, null, `auth`);
      if (response.ok && response.data && response.data.name) {
        this.mustResetPassword = response.data.mustResetPassword ?? false;
        for (const i in response.data) {
          if (Array.isArray(response.data[i])) {
            this[i] = response.data[i].map((v) => v);
          } else {
            this[i] = response.data[i];
          }
        }

        if (response.data.company) {
          this.company = { ...response.data.company };
        }

        this.hasAuthorized = true;
      } else {
        this.hasAuthorized = false;
      }
      this.app?.forceUpdate();
      r();
    });
  };

  refresh = async () => {
    return new Promise(async (r) => {
      if (this.hasAuthorized) {
        const response = await post(`refresh`, null, null, `auth`);
        if (
          response.ok &&
          response.status === 200 &&
          response.data.data !== 0
        ) {
          message.info(`Sesija pagarināta`);
        } else if (response.data?.error) {
          message.error(response.data.error);
        } else {
          message.error(`Neizdevās pagarināt sesiju`);
        }
        await this.sync();
      } else {
        this.hasAuthorized = false;
        message.error(`Lietotājs nav autorizējies`);
      }
      r();
    });
  };

  logout = async (display = true) => {
    return new Promise(async (r) => {
      const response = await post(`logout`, null, null, `auth`);
      if (response.ok && response.data && response.data.message) {
        this.hasAuthorized = false;
        display && message.success(response.data.message);
      } else if (response.data && response.data.message) {
        display && message.error(response.data.message);
      } else {
        display && message.error(`Neizdevās atslēgties`);
      }
      r();
    });
  };

  hasRole = (role) => {
    return this.roles.includes(role);
  };

  hasPermission = (permission) => {
    return this.permissions.includes(permission);
  };
}

const user = new User();
export { user };

export function can(action) {
  return user.hasPermission(action);
}
