import { Empty, Select, message } from "antd";
import React, { Component } from "react";
import { acquire } from "../api";

class Search extends Component {
  constructor(props) {
    super(props);

    this.options = props.options
      ? props.options.map((opt) => ({ ...opt }))
      : [];
    this.source = props.source ?? undefined;
    this.mutex = null;

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (!this.props.options || !this.props.defaultValue) {
      this.load(undefined, this.props.extraParameters ?? []);
    }
  }

  load = async (query, extra = []) => {
    const { state } = this;

    state.loading = true;
    this.setState(state);

    if (this.source) {
      if (this.mutex) {
        this.mutex.abort();
      }

      this.mutex = new AbortController();
      const params = [];
      if (query && query.length) {
        params.push({ key: `q`, value: query });
      }

      for (const item of extra ?? []) {
        params.push({ ...item });
      }

      const response = await acquire(this.source, 1, 50, params, {
        signal: this.mutex.signal,
      });

      let data = {};
      if (response.ok && response.status === 200 && response.data.data !== 0) {
        data = { ...response.data };
      } else if (response.data?.error) {
        message.error(response.data.error);
        return;
      } else {
        message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
        return;
      }

      state.options = data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
      }));
    }

    state.loading = false;
    this.setState(state);
  };

  render() {
    const options = this.state.options ?? this.props.options ?? [];
    return (
      <>
        <Select
          allowClear
          showSearch
          onSearch={(value) => this.load(value, this.props.extraParameters ?? [])}
          //onBlur={() => this.load(``, this.props.extraParameters ?? [])}
          filterOption={false}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nav rezultātu. Vai Jūsu ierīcei ir piekļuve internetam?"
            />
          }
          {...this.props}
          {...this.state}
          style={{ marginLeft: this.props.style?.marginLeft??0, ...this.props.style }}
          options={options}
        />
      </>
    );
  }
}

export default Search;
