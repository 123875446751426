import React from "react";
import BasicForm, { required } from "../lib/BasicForm";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, Input, Checkbox } from "antd";

class CountryPage extends BasicForm {
    tableLabel = `Valstu saraksts`;
    newLabel = `Reģistrēt valsti`;
    editLabel = `Labot valsti`;

    location = "/classifiers/country";
    source = `country`;

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
                <Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item name="name" label="Nosaukums" rules={[required]}>
                        <Input
                            placeholder="Norādiet nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="code" label="Kods" rules={[required]}>
                        <Input
                            placeholder="Norādiet kodu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="isInEU"
                        label="Atrodas Eiropas Savienībā"
                        valuePropName="checked"
                    >
                        <Checkbox>Jā, valsts ir daļa no ES</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default withRouter(CountryPage);
