import React, { Component } from 'react';
import { Table, Empty } from 'antd';

class SimpleTable extends Component {
    render() {
        const { props } = this;

        const columns = [];

        for (const col of props.columns) {
            col.dataIndex = col.key;
            if (col.hidden) {
                continue;
            }

            columns.push({ ...col })
        }

        return (
            <Table
				scroll={{x:true}}
                pagination={false}
                showSorterTooltip={false}
                style={{ width: `100%`, marginTop: 15 }}
                locale={{
                    emptyText: (
                        <Empty
                            description="Nav datu. Vai Jūsu ierīcei ir piekļuve internetam?"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ margin: `80px auto` }}
                        />
                    ),
                }}
                {...props}
                columns={columns}
            />
        );
    }
}

export default SimpleTable;
