import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
  matchPath
} from "react-router-dom";
import "./App.css";
import { Layout, Spin, notification } from "antd";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import LoginPage from "./pages/Login";

import routes from './lib/routes';
import ProfilePage from "./pages/Profile"
import { user, can } from "./lib/User";
import NoAccessPage from "./pages/NoAccess";
import ResetPasswordPage from "./pages/ResetPassword";

import { post } from './lib/api';
const { Content, Sider } = Layout;

// ^_^

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isMobile: false,
      collapsed: false,
      searchBarValue: ``,
    };
  }

  async componentDidMount() {
    await user.sync(this);

    this.setState({ loading: false });
    this.session();
  }

  session = async () => {
    while (true) {
      await new Promise(r => setTimeout(r, 1000 * 60 * 5));
      await user.refresh();
    }
  };

  search = () => {
    const { state } = this;

    if (state.searchBarVal?.length > 0) {
      console.log(state.searchBarVal);
      // fetch(state.searchBarVal);
      // return this.state.searchBarVal;
    }
  };

  render() {
    const { state, props } = this;

    let resetPasswordMatch = matchPath(props.location.pathname, { path: `/resetPassword/:id`, strict: true, exact: true });
    if (resetPasswordMatch && resetPasswordMatch?.params?.id) {
      return <ResetPasswordPage parent={this} token={resetPasswordMatch.params.id} />;
    }

    if (state.loading) {
      return (
        <div style={{ position: `absolute`, top: 0, left: 0, bottom: 0, right: 0, background: `#f0f2f5` }}>
          <Spin size="large" style={{ position: `absolute`, top: `50%`, left: `50%`, transform: `translate(-50%, -50%)` }} />
        </div>
      );
    }

    if (!user.hasAuthorized) {
      return <LoginPage parent={this} />;
    }

    function callPasswordChange() {
      post(`sendResetPasswordEMail`, { email: user.email }).then((response) => {
        if (response.ok && response.status === 200 && response.data.data !== 0) {
          notification.success({
            duration: 8,
            message: "Epasts nosūtīts",
            description: "Instrukcija nosūtīta uz Jūsu epastu.",
          })
        } else if (response.data?.error) {
          notification.error({
            duration: 0,
            message: response.data?.title,
            description: response.data?.error,
          });
        } else {
          notification.error({
            duration: 0,
            message: `500 Servera kļūda`,
            description: `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
          });
        }
      });

    }

    for (const route of routes) {
      if (route.sub) {
        for (const sub of route.sub) {
          if (!sub.ref) {
            sub.ref = React.createRef();
          }
        }
      } else if (!route.ref) {
        route.ref = React.createRef();
      }
    }
    let startPage;
    // Find first page (first in order from routes file) user has permission to view and make it default page
    routes.slice().reverse().forEach((route) => {
      if (route.sub) {
        route.sub.slice().reverse().forEach((sub) => {
          sub.permissions?.forEach((permission) => {
            if (can(permission)) {
              startPage = sub.key;
            }
          })
        })
      } else {
        route.permissions?.forEach((permission) => {
          if (can(permission)) {
            startPage = route.key;
          }
        })
      }
    })
    return (
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <NavBar parent={this} isMobile={state.isMobile} />
          <Content style={{ padding:state.isMobile?"0 0" : "0 20px", margin: "23px 0px 0px 0px" }}>

            <Layout
              className="site-layout-background"
              style={{ padding: "11px 0" }}
            >
              {
                state.isMobile && !state.collapsed && (
                  <div
                    style={{
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 9998,
                      opacity: 0.2,
                      position: `fixed`,
                      background: `gray`,
                    }}
                    onClick={() => this.setState({ collapsed: true })}
                  />
                )
              }
              {!user.mustResetPassword ?
                <>
                  <Sider
                    trigger={null}
                    breakpoint="lg"
                    collapsedWidth={0}
                    width={state.isMobile ? `100%` : 250}
                    collapsed={state.collapsed ?? false}
                    theme={state.isMobile ? `dark` : `light`}
                    onBreakpoint={(br) => {
                      this.setState({ isMobile: br, collapsed: br ? true : undefined });
                      if (!br) {
                        document.body.style.overflowY = `auto`;
                      }
                    }}
                    style={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      zIndex: 9999,
                      overflowY: `scroll`,
                      position: state.isMobile ? `fixed` : `relative`,
                    }}
                  >
                    <Sidebar isMobile={state.isMobile} parent={this} />
                  </Sider>

                  <Switch>
                    {routes.map((route) => {
                      let accessGranted = true;
                      if (route.permissions) {
                        accessGranted = false;
                        for (const action of route.permissions) {
                          accessGranted |= can(action);
                        }
                      }

                      return (
                        route.sub ? (
                          route.sub.map((sub) => {
                            if (sub.permissions) {
                              accessGranted = false;
                              for (const action of sub.permissions) {
                                accessGranted |= can(action);
                              }
                            }

                            return (
                              <Route exact={sub.exact} path={`/${sub.key}`} key={sub.key}>
                                {
                                  accessGranted ? (
                                    <sub.page searchBarVal={this.state.searchBarVal} ref={sub.ref} route={sub} port={state.port} isMobile={state.isMobile} />
                                  ) : (
                                      <NoAccessPage />
                                    )
                                }
                              </Route>
                            );
                          })
                        ) : (
                            <Route path={`/${route.key}`} key={route.key}>
                              {
                                accessGranted ? (
                                  <route.page searchBarVal={this.state.searchBarVal} ref={route.ref} route={route} port={state.port} isMobile={state.isMobile} />
                                ) : (
                                    <NoAccessPage />
                                  )
                              }
                            </Route>
                          )
                      );
                    })}
                    <Route path="/profile" exact>
                      <ProfilePage isMobile={state.isMobile}/>
                    </Route>
                    <Route exact path="/">
                      <Redirect to={startPage ? ("/" + startPage) : ("/ships")} />
                    </Route>
                    <Route exact path="/index.html">
                      <Redirect to="/offline" />
                    </Route>
                  </Switch>
                </>
                :
                <NoAccessPage
                  title="Lūdzu nomainiet savu paroli"
                  message="Izmantojiet pogu, lai uz epastu nosūtītu paroles mainīšanas instrukciju."
                  resetButton={true}
                  passwordResetFunction={callPasswordChange}
                />
              }
            </Layout>
          </Content>
        </Layout>
      </Router>
    );
  }
}

export default withRouter(App);
