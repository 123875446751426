import React from "react";
import BasicForm, { required } from "../lib/BasicForm";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, Input } from "antd";

class WasteGroupPage extends BasicForm {
    tableLabel = `Atkritumu grupas`;
    newLabel = `Pievienot atkritumu grupu`;
    editLabel = `Labot atkritumu grupu`;
    
    location = "/classifiers/wasteGroup";
    source = `wasteGroup`;

    form() {
		const {isMobile} = this.props;
        return (
            <Row>
                <Col span={isMobile?"":"12"} style={isMobile?{width:"90%", marginLeft:"5%"}:{}}>
                    <Form.Item name="name" label="Nosaukums" rules={[required]}>
                        <Input
                            placeholder="Norādiet nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="nameEn"
                        label="Nosaukums angliski"
                        rules={[]}
                    >
                        <Input
                            placeholder="Norādiet anglisko nosaukumu"
                            autoComplete={false}
                        />
                    </Form.Item>
                    <Form.Item name="orderNum" label="Kārtas numurs" rules={[]}>
                        <Input
                            placeholder="Norādiet kārtas numuru"
                            autoComplete={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default withRouter(WasteGroupPage);
