import React, { Component } from "react";
import "./App.css";
import "./css/Content.css";
import {
  Form,
  Layout,
  DatePicker,
  Button,
  Typography,
  Select,
  Spin,
  message,
  Card,
} from "antd";
import { withRouter } from "react-router-dom";
import { acquire, post } from "./lib/api";
import moment from "moment-timezone";
// import SingleColumnGraph from "./SingleColumnGraph";
// import TripleColumnGraph from "./TripleColumnGraph";

const { Content } = Layout;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const submitRoutes = {
  collectedWaste: "reportFirst",
  allCollected: "reportSecond",
};
const pdfRoutes = {
  collectedWaste: "reportPdf",
  allCollected: "reportSecondPdf",
};
const excelRoutes = {
  collectedWaste: "reportExcel",
  allCollected: "reportSecondExcel",
};

const reportTypes = ["collectedWaste", "allCollected"];

class Statistika extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReport: reportTypes[0],
      loading: true,
      shipData: [],
      shipOptionsData: [],
      shipTypeData: [],
      shipTypeOptionsData: [],
      companyData: [],
      companyOptionsData: [],
      portData: [],
      portOptionsData: [],
      wasteData: [],
      wasteOptionsData: [],
      error: false,
      generatedReport: false,
      html: "",
      query: "",
      shownGetDataError: false,
      // period: [],
    };
  }

  getShipData = async () => {
    let shipOptions = [];
    const response = await acquire("ships");
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      this.setState({ shipData: response.data.data });

      this.state.shipData.forEach((value) => {
        shipOptions.push(
          <Option value={value.imo}>
            {value.name} (IMO{value.imo})
          </Option>
        );
      });
      this.setState({ shipOptionsData: shipOptions });
    } else if (response.data && response.data.error) {
      !this.state.shownGetDataError && message.error(response.data.error);
      this.setState({ error: true, shownGetDataError: true });
    } else {
      !this.state.shownGetDataError &&
        message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
      this.setState({ error: true, shownGetDataError: true });
    }
  };

  getShipTypeData = async () => {
    let shipTypeOptions = [];
    const response = await acquire("shipTypes");
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      this.setState({ shipTypeData: response.data.data });

      this.state.shipTypeData.forEach((value) => {
        shipTypeOptions.push(<Option value={value.id}>{value.name}</Option>);
      });

      this.setState({ shipTypeOptionsData: shipTypeOptions });
    } else if (response.data && response.data.error) {
      !this.state.shownGetDataError && message.error(response.data.error);
      this.setState({ error: true, shownGetDataError: true });
    } else {
      !this.state.shownGetDataError &&
        message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
      this.setState({ error: true, shownGetDataError: true });
    }
  };

  getCompanyData = async () => {
    let companyOptions = [];
    const response = await acquire("companiesSelect");
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      this.setState({ companyData: response.data.data });

      this.state.companyData.forEach((value) => {
        companyOptions.push(<Option value={value.id}>{value.name}</Option>);
      });

      this.setState({ companyOptionsData: companyOptions });
    } else if (response.data && response.data.error) {
      !this.state.shownGetDataError && message.error(response.data.error);
      this.setState({ error: true, shownGetDataError: true });
    } else {
      !this.state.shownGetDataError &&
        message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
      this.setState({ error: true, shownGetDataError: true });
    }
  };

  getPortData = async () => {
    let portOptions = [];
    const response = await acquire("portIndex");
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      this.setState({ portData: response.data.data });

      this.state.portData.forEach((value) => {
        portOptions.push(<Option value={value.id}>{value.name}</Option>);
      });

      this.setState({ portOptionsData: portOptions });
    } else if (response.data && response.data.error) {
      !this.state.shownGetDataError && message.error(response.data.error);
      this.setState({ error: true, shownGetDataError: true });
    } else {
      !this.state.shownGetDataError &&
        message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
      this.setState({ error: true, shownGetDataError: true });
    }
  };

  getWasteData = async () => {
    let wasteOptions = [];
    const response = await acquire("wasteTypes");
    if (response.ok && response.status === 200 && response.data.data !== 0) {
      this.setState({ wasteData: response.data.data });

      this.state.wasteData.forEach((value) => {
        wasteOptions.push(<Option value={value.id}>{value.name}</Option>);
      });

      this.setState({ wasteOptionsData: wasteOptions });
    } else if (response.data && response.data.error) {
      !this.state.shownGetDataError && message.error(response.data.error);
      this.setState({ error: true, shownGetDataError: true });
    } else {
      !this.state.shownGetDataError &&
        message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
      this.setState({ error: true, shownGetDataError: true });
    }
  };

  submitData = async (values) => {
    if (values.period) {
      var tempPeriod = [
        moment(values.period[0]).format("YYYY-MM-DD"),
        moment(values.period[1]).format("YYYY-MM-DD"),
      ];
      values.period = tempPeriod;
    }
    const response = await post(
      submitRoutes[this.state.selectedReport],
      values
    );

    if (response.ok && response.status === 200 && response.data.data !== 0) {
      message.success(response.data.message);
      this.setState({
        generatedReport: true,
        html: response.data.html,
        query: values,
      });
    } else {
      message.error(`Kaut kas nogāja greizi, mēģiniet vēlreiz.`);
    }
  };

  componentDidMount = async () => {
    this.setState({ shownGetDataError: false });
    Promise.all([
      this.getShipData(),
      this.getShipTypeData(),
      this.getCompanyData(),
      this.getPortData(),
      this.getWasteData(),
    ]).then(() => {
      if (!this.state.error) {
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false }); // To no get caught in infinite loading
      }
    });
  };

  renderForm = () => {
    if (
      this.state.selectedReport === reportTypes[0] ||
      this.state.selectedReport === reportTypes[1]
    ) {
      return (
        <Form layout="vertical" name="reportForm" onFinish={this.submitData}>
          <Form.Item name="shipName" label="Kuģa nosaukums (IMO)">
            <Select
              allowClear
              className="antSelectType"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
            >
              {this.state.shipOptionsData}
            </Select>
          </Form.Item>

          {/* <Form.Item name="shipImo" label="Kuģa IMOs">
            <Input />
          </Form.Item> */}

          <Form.Item name="shipType" label="Kuģa tips">
            <Select
              allowClear
              className="antSelectType"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.shipTypeOptionsData}
            </Select>
          </Form.Item>

          <Form.Item name="wasteCollector" label="Atkritumu apsaimniekotājs">
            <Select
              allowClear
              className="antSelectType"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
            >
              {this.state.companyOptionsData}
            </Select>
          </Form.Item>

          <Form.Item name="port" label="Osta">
            <Select
              allowClear
              className="antSelectType"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
            >
              {this.state.portOptionsData}
            </Select>
          </Form.Item>

          <Form.Item name="period" label="Atskaites periods">
            <RangePicker
				allowClear
				placeholder={["Sākuma datums", "Beigu datums"]}
				style={{ borderRadius: "5px" }}
            />
          </Form.Item>

          <Form.Item name="wasteTypes" label="Atkritumu tipi">
            <Select
              allowClear
              className="antSelectType"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
            >
              {this.state.wasteOptionsData}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Ģenerēt
            </Button>
          </Form.Item>
        </Form>
      );
    }
  };

  renderPdfButton = () => {
    if (this.state.generatedReport) {
      let url =
        "/api/v1/" +
        pdfRoutes[this.state.selectedReport] +
        "?" +
        this.generateUrlParams();
      return (
        <Button href={url} target="_blank" type="primary" style={{marginRight:"15px"}}>
          Apskatīt PDF
        </Button>
      );
    } else {
      return null;
    }
  };

  renderExcelButton = () => {
    if (this.state.generatedReport) {
      let url =
        "/api/v1/" +
        excelRoutes[this.state.selectedReport] +
        "?" +
        this.generateUrlParams();
      return (
        <Button href={url} target="_blank" type="primary">
          Eksportēt XLSX
        </Button>
      );
    } else {
      return null;
    }
  };

  renderHtml = () => {
    if (this.state.html) {
      return this.state.html;
    } else {
      return null;
    }
  };

  generateUrlParams = () => {
    if (this.state.query) {
      var queryString = Object.keys(this.state.query)
        .map((key) => key + "=" + this.state.query[key])
        .join("&");
      return encodeURI(queryString);
    }
  };

  render() {
	  const { isMobile } = this.props;
	  if (!this.state.loading) {
      return (
        <Content style={{ padding:isMobile?"0px 10px": "9px 35px", minHeight: 280 }}>
          <Card
            title={
              <div className="contentHeader">
                <div className="contentHeaderName">
                  <Text type="secondary" style={{ fontSize: "11pt" }}>
                    Statistika
                  </Text>
                </div>
              </div>
            }
          >
            <div className="reportTypeDiv" style={isMobile?{display:"flex", flexDirection:"row", flexWrap:"wrap"}:{}}>
              <Text style={isMobile?{marginRight:"10px"}:{}}>Atskaites veids</Text>
              <Select
			  style={isMobile?{marginLeft:0, minWidth:"100%"}:{}}
                defaultValue="collectedWaste"
                className="reportTypeSelector"
                placeholder="Atskaites veids..."
                onSelect={(value, index) =>
                  this.setState({
                    selectedReport: value,
                    html: null,
                    generatedReport: false,
                  })
                }
              >
                <Option value="collectedWaste">
                  Kuģu nodoto atkritumu daudzums
                </Option>
                <Option value="allCollected">Visu tipu savāktais apjoms</Option>
              </Select>
            </div>
            <div className="reportFormDiv" style={isMobile?{width:"100%"}:{}}>{this.renderForm()}</div>
			<div style={isMobile?{display:"flex",flexDirection:"row",flexWrap:"wrap"}:{}}>
            	{this.renderPdfButton()}
            	{this.renderExcelButton()}
			</div>

            {/* <SingleColumnGraph />
            <div className="graphSpace" />
            <TripleColumnGraph /> */}
            <div
              className="reportTableDiv"
              dangerouslySetInnerHTML={{ __html: this.renderHtml() }}
            ></div>
          </Card>
        </Content>
      );
    } else {
      return (
        <Spin
          size="large"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translateY(-50%)`,
          }}
        />
      );
    }
  }
}

export default withRouter(Statistika);
