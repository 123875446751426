import { Button, Col, Form, Input, message, notification, Row } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { required } from "../lib/BasicForm";
import { user } from "../lib/User";
import Search from "../lib/widgets/Search";
import { emailValidator } from "./Login";
import { post } from "../lib/api";

class MyCompany extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      allowSubmit: true,
    };
  }

  formatData = (data) => {
    if (data) {
      for (const i in data) {
        if (data[i] === null) {
          data[i] = undefined;
        }
      }
    }
    
    return { ...data };
  };

  submit = async (data) => {
    this.setState({ allowSubmit: false });

    const response = await post(`companies`, data, user._company.id);
    if (response.ok) {
      message.success(response.data.message);
    } else {
      notification.error({
        duration: 0,
        message: response.data.title ?? `Sistēmas kļūda`,
        description:
          response.data.error ?? `Kaut kas nogāja greizi, mēģiniet vēlreiz.`,
      });
    }

    user.sync();

    this.setState({ allowSubmit: true });
  };

  render() {
    const { state } = this;
    const { isMobile } = this.props;

    return (
      <div
        style={{
          padding: 15,
          width: `100%`,
          minHeight: 250,
          paddingBottom: 50,
          position: `relative`,
        }}
      >
        <Row>
          <Col span={isMobile?"":"12"} style={isMobile?{width:"96%",marginLeft:"2%"}:{}}>
            <Form
              id="myCompanyForm"
              layout="vertical"
              ref={this.formRef}
              onFinish={this.submit}
              initialValues={this.formatData({ ...user._company })}
            >
              <Form.Item name="organizationId" label="Uzņēmuma APUS nosaukums">
                <Search
                  disabled
                  source="apusOrganizations"
                  placeholder="Norādiet uzņēmumu no APUS klasifikatoriem"
                  options={user?.__apusCompanyOptions}
                />
              </Form.Item>

              <Form.Item
                name="name"
                label="Uzņēmuma nosaukums"
                rules={[required]}
              >
                <Input
                  disabled
                  placeholder="Norādiet uzņēmuma nosaukumu"
                  autoComplete={false}
                />
              </Form.Item>
              <Form.Item
                name="shortName"
                label="Uzņēmuma īsais nosaukums"
                rules={[required]}
              >
                <Input
                  disabled
                  placeholder="Norādiet uzņēmuma īso nosaukumu"
                  autoComplete={false}
                />
              </Form.Item>
              <Form.Item name="email" label="E-pasts" rules={[emailValidator]}>
                <Input placeholder="Norādiet e-pastu" autoComplete={false} />
              </Form.Item>

              <Form.Item name="phone" label="Tel. numurs">
                <Input
                  placeholder="Norādiet tel. numuru"
                  autoComplete={false}
                />
              </Form.Item>

              <Form.Item name="website" label="Mājaslapa">
                <Input placeholder="Norādiet mājaslapu" autoComplete={false} />
              </Form.Item>
              <Form.Item
                name="pvnNr"
                label="PVN numurs"
                rules={[required]}
              >
                <Input
                  name="pvnNr"
                  disabled
                  placeholder="Norādiet PVN numuru"
                  autoComplete={false}
                  onChange={() => this.forceUpdate()}
                />
              </Form.Item>
              <Form.Item
                name="regNr"
                label="Reģistrācijas nr."
                rules={[required]}
              >
                <Input
                  disabled
                  placeholder="Norādiet reģistrācijas nr."
                  autoComplete={false}
                />
              </Form.Item>

              <Form.Item name="transpLic" label="Transportētāja licence">
                <Search
                  source="apusTranspLicence"
                  placeholder="Izvēlaties transportētāja licensi"
                  extraParameters={[
                    {
                      key: `organizationId`,
                      value: user?._company?.organizationId ?? null,
                    },
                  ]}
                  options={user?.__apusTranspLicOptions}
                />
              </Form.Item>
              <Form.Item name="receiverLic" label="Saņēmēja licence">
                <Search
                  source="apusReceiverLicence"
                  placeholder="Izvēlaties saņēmēja licensi"
                  extraParameters={[
                    {
                      key: `organizationId`,
                      value: user?._company?.organizationId ?? null,
                    },
                  ]}
                  options={user?.__apusRecLicOptions}
                />
              </Form.Item>

              <Form.Item name="sendMethod" label="Sūtīšanas veids">
                <Search
                  allowClear={false}
                  source="apusSendMethods"
                  placeholder="Izvēlaties sūtīšanas veidu"
                />
              </Form.Item>

              <Form.Item name="apusUsername" label="APUS lietotājvārds">
                <Input
                  placeholder="Ievadiet lietotājvārdu"
                  autoComplete={false}
                />
              </Form.Item>

              <Form.Item name="apusPassword" label="APUS parole">
                <Input.Password
                  placeholder="Ievadiet paroli"
                  autoComplete={false}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <div style={{ position: `absolute`, bottom: 0, right: 20 }}>
          <Button
            form="myCompanyForm"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 10 }}
            disabled={!state.allowSubmit}
            loading={!state.allowSubmit}
          >
            Saglabāt
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(MyCompany);
